import React from 'react';
import styles from './ProductRange.module.scss';
import { calcPercentage } from '../../utils/utils';

export default function ProductRange({ product, currency = 'euroValue', color = null }) {
  function getRange() {
    let range = product?.range && product?.range?.toFixed(2);
    if (product?.productsList?.length > 0) {
      const pricesLists = product.productsList
        .map((v) => v.price[currency]);
      const min = pricesLists[0];
      const max = pricesLists[pricesLists.length - 1];
      range = calcPercentage(min, max).toFixed(2);
    }
    return range;
  }
  return (
    <div
      className={`${styles.container} ${product?.productsList?.length === 0 ? styles.empty : ''}`}
    >
      <div className={styles.labels}>
        <div className={styles.name}>
          <div
            className={styles.puce}
            style={{
              backgroundColor: color
              || (product.productsList && product.productsList[0].product.vendor.color),
            }}
          />
          <p>{product.product.name}</p>
        </div>
        <p className={`${styles.rate} ${product?.productsList?.length === 0 ? styles.hide : ''} ${getRange() < 0 ? styles.danger : ''}`}>
          {getRange() > 0 && '+'}
          {getRange() < 0 && '-'}
          {getRange()}
          {' '}
          %
        </p>
      </div>
      {product?.productsList && product?.price
      && (
      <div className={styles['range-infos']}>
        <div className={styles.block}>
          <p className={styles.label}>Price begin</p>
          <p>
            {product?.productsList?.length > 0
            && product?.productsList[0]?.price[currency].toFixed(2)}
            {' '}
            {currency === 'euroValue' ? '€' : product?.price.currency.code}
          </p>
        </div>
        <div className={styles.block}>
          <p className={styles.label}>Price end</p>
          <p>
            {product?.productsList.length > 0
            && product?.productsList[product.productsList.length - 1]?.price[currency]?.toFixed(2)}
            {' '}
            {' '}
            {currency === 'euroValue' ? '€' : product?.price.currency.code}
          </p>
        </div>
      </div>
      )}
    </div>
  );
}
