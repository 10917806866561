import React, { useEffect, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import Select from '../Select';
import styles from './GroupedSelect.module.scss';

function GroupedSelect({
  options,
  selected,
  handleChangeQuery,
  topCompetitor = null,
  selectAll = false,
  count = null,
  isMulti = true,
  isSearchable = true,
  selectLabel = false,
  label,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [allOptions, setAllOptions] = useState([]);
  const allSelected = selectAll && selected.length > 0 && selected.length === allOptions.length;

  function handleSelectAll() {
    if (allSelected) {
      return handleChangeQuery([]);
    }
    return handleChangeQuery(allOptions);
  }

  useEffect(() => {
    if (options) {
      const resetAllOptions = [];
      options.forEach((g) => {
        g.lists.forEach((o) => {
          resetAllOptions.push(o.value);
        });
      });
      setAllOptions(resetAllOptions);
    }
  }, [options]);

  return (
    <div className={`${styles.container} ${isOpen ? styles.open : ''}`}>
      <div className={styles.title}>
        <div className={styles.label}>
          {label}
          {' '}
          {isMulti && selected.length > 0 && count === null && (
            <span className={styles.count}>
              {selected.length}
            </span>
          )}
          {isMulti && selected.length > 0 && count > 0 && (
            <span className={styles.count}>
              {count}
            </span>
          )}
        </div>
        <button
          type="button"
          className={styles.toggle}
          onClick={() => setIsOpen(!isOpen)}
        >
          <FiChevronDown />
        </button>
      </div>
      <div className={styles['add-btns']}>
        {(selectAll || topCompetitor)
          && (
            <div className={styles.active}>
              {selectAll && (
                <button
                  type="button"
                  onClick={() => handleSelectAll()}
                  className={styles.selectAll}
                >
                  {allSelected ? 'Remove all' : 'Add all'}
                </button>
              )}
              {topCompetitor && (
                <button
                  type="button"
                  onClick={() => handleChangeQuery(topCompetitor)}
                  className={styles.selectAll}
                >
                  Top competitors
                </button>
              )}
            </div>
          )}
      </div>
      {isSearchable
      && (
        <div className={styles.active}>
          <input
            value={search}
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      )}
      <div className={styles.options}>
        {options.filter((opt) => opt.label !== '#').map((opt) => (
          <Select
            key={opt.value}
            options={opt.lists}
            groupSearch={search}
            handleChangeQuery={(value) => handleChangeQuery(value)}
            selected={selected}
            selectedGroup={opt.lists.filter((c) => (selected.find((s) => c.value === s)))
              .map((c) => c.value)}
            label={opt.label}
            color={opt?.color}
            groupId={selectLabel ? opt.value : null}
            isSearchable={false}
            isGrouped
          />
        ))}
      </div>

    </div>
  );
}

export default GroupedSelect;
