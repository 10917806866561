const style = {
  container: (provided) => ({ ...provided, background: '#FAFBFB' }),
  control: (provided, state) => ({
    ...provided,
    background: '#FAFBFB',
    minHeight: 40,
    borderRadius: 3,
    boxShadow: '0 0 0 1px transparent',
    border: '1px solid #837FAE',
    '&:hover': {
      border: state.isFocused ? '1px solid #837FAE' : '',
    },
  }),
  placeholder: (provided) => ({ ...provided, fontSize: '14px' }),
  valueContainer: (provided) => ({ ...provided }),
  singleValue: (provided) => ({ ...provided, fontSize: '14px', color: '#FFFFFF' }),
  multiValue: (provided) => ({
    ...provided, fontSize: '14px', backgroundColor: '#041483',
  }),
  multiValueLabel: (provided) => ({ ...provided, color: '#FFFFFF' }),
  multiValueRemove: (provided) => ({ ...provided, color: '#FFFFFF' }),
  menu: (provided) => ({ ...provided, background: '#FAFBFB' }),
  input: (provided) => ({
    ...provided,
    color: '#FFFFFF',
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({ ...provided }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    zIndex: 3000,
    backgroundColor: state.isSelected ? '#33325A' : '#FAFBFB',
    '&:hover': {
      backgroundColor: '#15133A',
      color: '#FFFFFF',
    },
  }),
  clearIndicator: (provided) => ({ ...provided }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: (provided) => ({ ...provided }),
};

export default style;
