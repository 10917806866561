import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../assets/images/logo.svg';
import {
  resetPasswordAction,
} from '../../actions/authActions';
import {
  InputPassword, Button, ErrorField,
} from '../../lib/HooksFormFields';
import styles from './Auth.module.scss';
import { ERROR_AUTH } from '../../actions/types';

export default function Reset() {
  const dispatch = useDispatch();
  const { error, message } = useSelector((state) => state.auth);
  const { token } = useParams();
  const [succeed, setSucceed] = useState(false);
  const successMessage = 'Your password has been changed.';
  const {
    control,
    handleSubmit,
    setError,
    formState: {
      errors,
    },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  function onSubmit(values) {
    dispatch({
      type: ERROR_AUTH,
      payload: null,
    });
    if (!values.password) {
      return setError('password', {
        type: 'manual',
        message: 'Password required',
      });
    }
    setIsLoading(true);
    return resetPasswordAction(dispatch, token, values).then(() => {
      setSucceed(true);
    });
  }

  useEffect(() => {
    setIsLoading(false);
  }, [error, message]);

  return (
    <div className={styles.container}>
      <div className={styles['col-form']}>
        <form>
          <div className={`${styles.title} bold`}>
            <img src={logo} alt="logo" />
            <span className={`${styles.name} bold`}>MARKET COMPARISON & ANALYSIS TOOL</span>
            <br />
            <span className="regular">Incorporating Price Tracker ByOctapharma</span>
          </div>
          <InputPassword
            name="password"
            label="Password"
            control={control}
            className="login"
            rules={{ required: 'Password required' }}
            password
            placeholder="Password"
          />
          <ErrorField message={errors.password?.message} />
          <div className={styles['btn-container']}>
            <Button
              handleClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            >
              SUBMIT
            </Button>
          </div>
          <div className={styles.link}>
            <NavLink to="/login">Login</NavLink>
          </div>
          {succeed
          && (
            <p className={styles.message}>
              {successMessage}
            </p>
          )}
          {error && (
          <p className={styles.message}>
            <span className={styles.error}>
              {error.data.error}
            </span>
          </p>
          )}
        </form>
      </div>
    </div>
  );
}
