import React from 'react';
import styles from './Timeline.module.scss';

export default function Timeline({
  options, handleChangeValue, selected, selectedStart,
}) {
  return (
    <div className={styles.container}>
      {options.map((opt) => (
        <button
          key={opt.label}
          type="button"
          className={`
            ${styles.date} ${opt?.label?.toString().includes('Q1') ? styles.year : ''}
            ${(selectedStart && selectedStart > opt.value) ? styles['not-range'] : ''}
            ${(selectedStart && selectedStart <= opt.value && selected > opt.value) ? styles['in-range'] : ''}
            ${selected === opt.value ? styles.active : ''} ${selectedStart === opt.value ? styles.start : ''}
          `}
          onClick={() => handleChangeValue(opt.value)}
        >
          <p>{opt.label}</p>
          <div className={styles.bar} />
        </button>
      ))}
    </div>
  );
}
