import React from 'react';
import { ResponsiveLine } from '@nivo/line';

function Tooltip(d, currencyCode) {
  const { point } = d;
  const { serieColor, data } = point;
  return (
    <div
      style={{
        padding: 8,
        marginTop: 20,
        maxWidth: 130,
        marginRight: 80,
        color: '#A1A0AF',
        alignItems: 'center',
        fontFamily: 'Poppins-Regular',
        background: '#FFFFFF',
        borderRadius: 3,
      }}
    >
      <p style={{ color: serieColor, margin: 0, fontWeight: 'bold' }}>
        {data.name}
      </p>
      <strong>
        <span style={{ color: '#415B94' }}>
          <span style={{ opacity: 0.5 }}>{data.x}</span>
          <br />
          {' '}
          {' '}
          {data.y.toFixed(2)}
          {' '}
          {currencyCode}
        </span>
        <br />
      </strong>
    </div>
  );
}

function LineChart({
  data = [],
  currencyCode,
  axisLeft = null,
  min = null,
  max = null,
  margin = {
    top: 10, right: 0, bottom: 22, left: 0,
  },
}) {
  const theme = {
    grid: {
      line: {
        stroke: '#A8A8AB',
        strokeDasharray: '4 4',
      },
    },
    axis: {
      ticks: {
        line: {
          stroke: 'transparent',
        },
        text: {
          fill: '#A8A8AB',
        },
      },
    },
  };
  return (
    <ResponsiveLine
      data={data}
      margin={margin}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: min || 'auto',
        max: max || 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={axisLeft}
      theme={theme}
      tooltip={(d) => Tooltip(d, currencyCode)}
      enableGridX={false}
      enablePoints
      pointSize={3}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      colors={(d) => d.color}
      pointLabelYOffset={-15}
      enableCrosshair={false}
      useMesh
      legends={[]}
    />
  );
}

export default LineChart;
