import React, { useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { IoArrowBack } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { getPopulationByKey } from '../../utils/utils';
import LineChart from '../LineChart/LineChart';
import ProductPrice from '../ProductPrice/ProductPrice';
import ProductRange from '../ProductRange/ProductRange';
import styles from './CountryMapData.module.scss';

export default function CountryMapData({ activeCountry, handleChangeQuery }) {
  const { lists, filters } = useSelector((state) => state.prices);
  const [population, setPopulation] = useState(null);
  const infos = activeCountry?.properties?.products?.length > 0
  && activeCountry?.properties?.products[0];

  const countryId = infos?.geography?._id;
  const isOnSearch = filters.geographies.find((id) => id === countryId);

  const marketProducts = activeCountry?.properties?.products.filter((p) => (
    p.product.type === 'In-Market Prices' ? p : null
  )).sort((a, b) => (b.price.euroValue > a.price.euroValue ? 1 : -1));

  const registeredProducts = activeCountry?.properties?.products.filter((p) => (
    p.product.type !== 'In-Market Prices' ? p : null
  )).sort((a, b) => (b.price.euroValue > a.price.euroValue ? 1 : -1));

  const linesData = activeCountry?.properties?.products?.map(
    (p) => ({
      id: p.product.name,
      color: p.productsList && p.productsList[0].product.vendor.color,
      data: lists?.periods?.filter(
        (d) => d?.value <= parseFloat(filters.yearQuarter)
        && d?.value >= parseFloat(filters.yearQuarterStart),
      ).map((item) => ({
        y: p?.productsList?.find(
          (pr) => pr.price.yearQuarter === item.value,
        )?.price[filters.currency]
          ? p?.productsList?.find(
            (pr) => pr.price.yearQuarter === item.value,
          )?.price[filters.currency] : null,
        x: item.label,
        name: p.product.name,
      })),
    }),
  );

  async function fetchPop() {
    const pop = await getPopulationByKey(infos.geography.code);
    setPopulation(pop);
  }

  useEffect(() => {
    if (infos?.geography?.code) {
      fetchPop();
    }
  }, [infos]);

  function handleChangeProduct(id) {
    const found = filters.products.find((p) => p === id);
    if (found) {
      return handleChangeQuery({ products: filters.products.filter((p) => p !== id) });
    }
    return handleChangeQuery({ products: [...filters.products, id] });
  }

  return (
    <>
      <div className={`${styles.infos} ${activeCountry ? styles.active : ''}`}>
        <button
          type="button"
          className="no-canvas"
          id="back"
        >
          <IoArrowBack />
          Back to the map
        </button>
        <h4>{infos?.geography?.name}</h4>
        <div />
      </div>
      <div
        id="products"
        className={`${styles.products} ${activeCountry ? styles.active : ''}`}
      >
        {activeCountry?.properties?.products?.length < 4
        && activeCountry?.properties?.products?.map((p) => (
          <div
            key={p.product._id}
            className={styles.product}
          >
            <p className={styles.label}>{p.product.name}</p>
            <div
              className={styles.circle}
              style={{
                width: `${p.size}px`,
                height: `${p.size}px`,
                maxHeight: '240px',
                borderRadius: `${p.size}px`,
                backgroundColor: p.priceColor || p.color,
              }}
            />
          </div>

        ))}
      </div>
      <div className={`${styles.panel} ${activeCountry ? styles.active : ''}`}>
        <div className={styles.header}>
          <div className={styles.title}>
            <h4>
              {infos?.geography?.name}
            </h4>
            <p>
              {infos?.geography?.region?.name?.toLowerCase()}
            </p>
          </div>
          <div className={styles.info}>
            {infos?.geography
            && (
            <>
              <div className={styles.col}>
                <p>Population (External Source)</p>
                <h4>
                  {population}
                  {' '}
                  MM
                </h4>
              </div>
              <div className={styles.col}>
                <p>Board member</p>
                <h4>
                  {infos?.geography?.boardMember?.name}
                </h4>
              </div>
            </>
            )}
          </div>
          {countryId && !isOnSearch
          && (
            <button
              type="button"
              className={styles['add-data']}
              onClick={() => handleChangeQuery({
                geographies: [...filters.geographies, countryId],
              })}
            >
              <BiPlus />
            </button>
          )}
        </div>
        <div className={styles.prices}>
          <div className={styles.currencies}>
            <button
              type="button"
              className={filters.currency === 'euroValue' ? styles.active : ''}
              onClick={() => handleChangeQuery({ currency: 'euroValue' })}
            >
              Euro
            </button>
            <button
              type="button"
              className={filters.currency === 'localValue' ? styles.active : ''}
              onClick={() => handleChangeQuery({ currency: 'localValue' })}
            >
              Local currency
            </button>
          </div>
          {marketProducts?.length > 0
            && (
            <div>
              <h3>In-Market Product Prices</h3>
              {!filters.yearQuarterStart && marketProducts?.map((p) => (
                <ProductPrice
                  key={p.product._id}
                  handleChangeProduct={(id) => handleChangeProduct(id)}
                  productIsActive={filters.products.find((pr) => pr === p.product._id)}
                  currency={filters.currency}
                  product={p}
                />
              ))}
              {filters.yearQuarterStart
              && marketProducts?.map((p) => (
                <ProductRange
                  key={p.product._id}
                  currency={filters.currency}
                  product={p}
                />
              ))}
            </div>
            )}

          {registeredProducts?.length > 0
            && (
            <div>
              <h3>Octapharma Registered Prices</h3>
              {!filters.yearQuarterStart && registeredProducts?.map((p) => (
                <ProductPrice
                  key={p.product._id}
                  handleChangeProduct={(id) => handleChangeProduct(id)}
                  productIsActive={filters.products.find((pr) => pr === p.product._id)}
                  currency={filters.currency}
                  product={p}
                />
              ))}
              {filters.yearQuarterStart
              && registeredProducts?.map((p) => (
                <ProductRange
                  key={p.product._id}
                  currency={filters.currency}
                  product={p}
                />
              ))}
            </div>
            )}

          {filters.yearQuarterStart && linesData?.length > 0 && filters.products.length > 0
            && (
              <div className={styles.lines}>
                <LineChart id="product-chart" data={linesData} currencyCode={filters.currency === 'localValue' ? infos?.price?.currency?.code : '€'} />
                <div className={styles.legend}>
                  <p>{lists?.periods?.find((p) => p?.value === filters.yearQuarterStart)?.label}</p>
                  <p>{lists?.periods?.find((p) => p?.value === filters.yearQuarter)?.label}</p>
                </div>
              </div>
            )}
        </div>
      </div>

    </>
  );
}
