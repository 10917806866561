import axios from 'axios';
import { ERROR_AUTH, USER_AUTH } from './types';

export const {
  REACT_APP_API_URL: API_URL,
} = process.env;

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error, error.response);
  if (error?.response?.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    dispatch({
      type: ERROR_AUTH,
      payload: error.response.status,
    });
    dispatch({
      type: USER_AUTH,
      payload: null,
    });
  } else {
    dispatch({
      type,
      payload: error.response,
    });
  }
}

export function getData(errorType, url, dispatch, isAuthReq = false) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.get(requestUrl, isAuthReq ? config : null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function deleteData(errorType, url, dispatch, isAuthReq = true) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');

    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.delete(requestUrl, isAuthReq ? config : null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function putData(action, errorType, url, dispatch, data, isAuthReq = true) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');

    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.put(requestUrl, data, isAuthReq ? config : null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(action);
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function postData(
  action,
  errorType,
  url,
  dispatch,
  data,
  isAuthReq = false,
  isFile = false,
) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const config = {
      timeout: 1000 * 60,
    };
    if (isFile) {
      config.responseType = 'blob';
    }
    const token = localStorage.getItem('token');

    if (isAuthReq) {
      config.headers = {
        Authorization: token,
      };
    }

    axios.post(requestUrl, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(action);
        errorHandler(dispatch, error, errorType);
      });
  });
}
