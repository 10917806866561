import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LineChart from '../LineChart/LineChart';
import styles from './GroupedProducts.module.scss';

function CompareChart({ segment, country }) {
  const { filters, countriesList } = useSelector((state) => state.prices);
  const [linesData, setLinesData] = useState([]);
  const [scale, setScale] = useState();
  useEffect(() => {
    if (filters?.priceView === 'chart') {
      const lines = segment.products?.map(
        (p) => ({
          id: `${country.name}-${p?.name}-${segment._id}`,
          color: p.vendor.color,
          data: p.data.map((d) => ({
            ...d, id: `${country.name}-${p?.name}-${d.period}-${segment._id}`, x: d.period, name: p.name, y: d[filters?.currency], unit: filters?.currency === 'euroValue' ? '€' : d.currency,
          })) || [],
        }),
      );
      setLinesData(lines);
    }
    if (filters?.currency === 'euroValue') {
      const values = [];
      countriesList.forEach((c) => {
        const products = c.products.filter((p) => p.treatmentSegment?._id === segment?._id);
        products.forEach((p) => {
          p.data.forEach((v) => {
            if (v.euroValue) {
              values.push(v.euroValue);
            }
          });
        });
      });
      setScale({ min: Math.min(...values), max: Math.max(...values) });
    } else {
      setScale();
    }
  }, [segment.products, countriesList, filters?.priceView, filters?.currency]);
  return (
    <div className={styles.compare}>
      <div className={styles.currency}>
        {filters.currency === 'localValue' ? country?.currency?.value : 'Euro'}
      </div>
      <div className={styles.chart}>
        <LineChart
          id={`${country.name}-chart`}
          data={linesData}
          min={scale?.min}
          max={scale?.max}
          currencyCode={filters.currency === 'localValue' ? country?.currency?.value : '€'}
          margin={{
            top: 15, right: 0, bottom: 22, left: 40,
          }}
          axisLeft={{
            format: (value) => {
              if (value < 10 && !Number.isInteger(value)) {
                return value.toFixed(2);
              }
              return value.toFixed(0);
            },
            orient: 'left',
            tickValues: 4,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
        />
      </div>
      <div className={styles.labels}>
        <p className={styles.label}>
          {linesData[0]
            ?.data[0]?.period}

        </p>
        <p className={styles.label}>
          {linesData[0]
            ?.data[linesData[0].data.length - 1]?.period}
        </p>
      </div>
    </div>
  );
}

export default function GroupedProducts({ products, country }) {
  const [productsBySegment, setProductsBySegments] = useState([]);

  useEffect(() => {
    if (products) {
      const segmentsList = [];
      products.forEach((p) => {
        if (!segmentsList.find((s) => s._id === p.treatmentSegment?._id)) {
          const segmentProducts = products.filter((pr) => {
            if (pr.treatmentSegment?._id === p.treatmentSegment?._id) {
              return pr;
            }
            return null;
          });
          const segmentData = {
            _id: p.treatmentSegment._id,
            name: p.treatmentSegment?.name,
            products: segmentProducts,
          };
          segmentsList.push(segmentData);
        }
      });
      setProductsBySegments(segmentsList);
    }
  }, [products]);
  return (
    <div className={styles.container}>
      {productsBySegment?.map((s) => (
        <div key={`${s._id}-${country?._id}`}>
          <div className={styles.segment}>
            <h4>{s?.name}</h4>
          </div>
          <CompareChart segment={s} country={country} />
        </div>
      ))}
    </div>
  );
}
