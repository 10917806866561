/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import geojson from './world-map-110.json';
import capitals from './capitals.json';

import styles from './Map.module.scss';
import {
  hexToRGBA, getCountryBounds, getPriceRate, getPriceAbs,
} from '../../utils/utils';
import Tooltip from '../Tooltip/Tooltip';
import CountryMapData from '../CountryMapData/CountryMapData';

export default function Map({
  data, mapWidth, mapHeight, mapId, enableZoom, filters, lists, getProductColor, handleChangeQuery,
}) {
  const mapRef = useRef(null);
  const [activeCountry, setActiveCountry] = useState();
  const [updatedMap, setUpdatedMap] = useState();
  const primary = '#041483';
  const sizeMinRange = d3.scaleLinear()
    .domain([-100, 0])
    .range([160, 15]);

  const sizeMaxRange = d3.scaleLinear()
    .domain([0, 100])
    .range([15, 160]);

  const [tooltipData, setTooltipData] = useState({ x: mapWidth / 2, y: mapHeight / 2 });

  async function drawMap() {
    const geojsonData = { ...geojson };
    let selectedCountry = activeCountry;
    let maxCountrySize = 0;
    let maxRange = 0;
    let minRange = 0;
    const strokeWidth = 0.35;

    // const maxCitySize = 0;
    const height = mapHeight;
    const width = mapWidth;
    const isRange = filters.yearQuarterStart;
    const markersData = [];
    const geoData = { ...geojsonData, features: [] };
    geojsonData.features.filter((f) => f.properties.name_en !== 'Antarctica').forEach((el) => {
      const c = { ...el };
      c.properties.range = null;
      c.properties.total = null;
      c.properties.color = null;
      c.properties.products = null;
      c.properties.isClick = false;
      if (filters.products.length === 0) {
        if (isRange) {
          const total = data?.filter((d) => d?.geography?.code === el.properties.wb_a2)
            .sort((a, b) => b.price.yearQuarter > a.price.yearQuarter);
          const start = total
            .filter((t) => t.price.yearQuarter === filters.yearQuarterStart);

          const countStart = [...new Set(start.map((item) => item.product.name))].length;

          const end = total
            .filter((t) => t.price.yearQuarter === filters.yearQuarter);

          const countEnd = [...new Set(end.map((item) => item.product.name))].length;
          const diff = countEnd - countStart;

          if (countEnd >= 0 && countStart >= 0) {
            if (diff > maxRange) { maxRange = diff; }
            if (diff < minRange) { minRange = diff; }
            const products = [];
            total.forEach((p) => {
              const priceRange = getPriceRate(p.product._id, el.properties.wb_a2, data)?.value;
              const productsList = getPriceRate(
                p.product._id,
                el.properties.wb_a2,
                data,
              )?.productsList;
              if (!products.find((pr) => pr.product._id === p.product._id)) {
                products.push({
                  ...p,
                  barSize: getProductSizePerPeriod(p, [4, 100]),
                  size: priceRange >= 0 ? sizeMaxRange(priceRange) : sizeMinRange(priceRange),
                  color: p.color,
                  range: priceRange,
                  priceColor: priceRange >= 0 ? '#489F80' : '#FA5C4F',
                  productsList,
                });
              }
            });
            if (products.length > 0) {
              c.properties.start = countStart;
              c.properties.end = countEnd;
              c.properties.range = diff?.toFixed();
              c.properties.color = diff >= 0 ? '#489F80' : '#FA5C4F';
              c.properties.isClick = true;
              c.properties.products = products.sort((a, b) => b.range - a.range);
            }
          }
        } else {
          const total = data?.filter((d) => d?.geography?.code === el?.properties?.wb_a2)
            .filter((t) => t.price.yearQuarter === filters.yearQuarter);
          // if (el.properties.wb_a2 === 'US') {
          //   console.log(el.properties.wb_a2, total);
          // }
          const countTotal = [...new Set(total.map((item) => item.product.name))].length;
          if (countTotal) {
            if (countTotal > maxCountrySize) { maxCountrySize = countTotal; }
            c.properties.total = countTotal.toFixed();
            c.properties.isClick = true;
            c.properties.color = primary;
            c.properties.products = total.map((p) => ({
              ...p,
              size: getProductSizePerPeriod(p, [20, 220]),
              barSize: getProductSizePerPeriod(p, [4, 100]),
              color: p.product.vendor.color,
            })).sort((a, b) => b.barSize - a.barSize);
          }
        }
      } else if (isRange) {
        const found = capitals.find((ca) => ca?.CountryCode === el.properties.wb_a2);
        let marker = null;
        const total = data?.filter((d) => d.geography.code === el.properties.wb_a2)
          .sort((a, b) => b.price.yearQuarter > a.price.yearQuarter);
        if (found && total.length > 0) {
          const productsCount = [...new Set(total.map((t) => t.product._id))].length;
          c.properties.products = [];
          c.properties.isRange = true;
          c.properties.isClick = false;
          total.forEach((p) => {
            const foundProduct = c.properties.products.find(
              (pr) => pr.product._id === p.product._id,
            );
            const priceRange = getPriceRate(p.product._id, el.properties.wb_a2, data)?.value;
            let productsList = getPriceRate(p.product._id, el.properties.wb_a2, data)?.productsList;
            const priceAbs = getPriceAbs(
              p.product._id,
              el.properties.wb_a2,
              data,
            )?.value.toFixed(2);
            productsList = productsList.map((pr) => {
              const obj = { ...pr };
              obj.abs = priceAbs;
              return obj;
            });

            if (!foundProduct) {
              const obj = {
                ...p,
                range: priceRange,
                abs: priceAbs,
                size: priceRange >= 0 ? sizeMaxRange(priceRange) : sizeMinRange(priceRange),
                color: p.color,
                priceColor: priceRange >= 0 ? '#489F80' : '#FA5C4F',
                productsList,
              };
              c.properties.products.push(obj);
            }
          });
          const diff = c.properties.products.map((p) => p.range).reduce(
            (prev, current) => prev
              + current,
            0,
          ) / c.properties.products.length;

          if (!Number.isNaN(diff) && Number.isFinite(diff)) {
            if (diff > maxRange) { maxRange = diff; }
            if (diff < minRange) { minRange = diff; }
            marker = {
              size: productsCount,
              color: diff >= 0 ? '#489F80' : '#FA5C4F',
              lat: parseFloat(found.CapitalLatitude),
              lng: parseFloat(found.CapitalLongitude),
              country: total[0].geography.name,
              products: c.properties.products,
              range: diff?.toFixed(),
              feature: c,
            };
          }
        }
        if (marker) {
          markersData.push(marker);
        }
      } else {
        const found = capitals.find((ca) => ca?.CountryCode === el.properties.wb_a2);
        let marker = null;
        const total = data?.filter((d) => d.geography.code === el.properties.wb_a2)
          .filter((t) => t.price.yearQuarter === filters.yearQuarter)
          .map((t) => ({ ...t, color: getProductColor(t.product._id) }));

        if (found && total.length > 0) {
          const productsCount = [...new Set(total.map((t) => t.product._id))].length;
          c.properties.products = total.map((p) => ({
            ...p,
            size: getProductSizePerPeriod(p, [20, 220]),
            barSize: getProductSizePerPeriod(p, [4, 100]),
          })).sort((a, b) => b.barSize - a.barSize);
          c.properties.isClick = false;
          marker = {
            size: productsCount,
            color: productsCount > 1 ? primary : getProductColor(total[0].product._id),
            lat: parseFloat(found.CapitalLatitude),
            lng: parseFloat(found.CapitalLongitude),
            country: total[0].geography.name,
            products: total,
            feature: c,
          };
        }
        if (marker) {
          markersData.push(marker);
        }
      }
      if (el.properties.name_en !== 'Antarctica') {
        geoData.features.push(c);
      }
    });
    setUpdatedMap(geoData.features);

    const opacityMinRange = d3.scaleLinear()
      .domain([minRange, 0])
      .range([1, 0.1]);

    const opacityMaxRange = d3.scaleLinear()
      .domain([0, maxRange])
      .range([0.1, 1]);

    const opacityRange = d3.scaleLinear()
      .domain([0, maxCountrySize])
      .range([0.1, 1]);

    function getProductSizePerPeriod(p, range = [0, 100]) {
      const sameProducts = data.filter(
        (t) => t.product._id === p.product._id,
      ).map((c) => c.price.euroValue);
      const max = Math.max.apply(null, sameProducts);
      const min = Math.min.apply(null, sameProducts);
      const size = d3.scaleLinear()
        .domain([min, max])
        .range(range);
      return size(p.price.euroValue);
    }

    if (d3.select(`#svg-${mapId}`)) {
      d3.select(`#svg-${mapId}`).remove();
    }

    const center = d3.geoCentroid(geoData);
    const scale = 150;
    const offset = [(width / 2) + 70, (height / 2 - 50)];

    const projection = d3.geoMercator()
      .scale(scale)
      .center(center)
      .translate(offset);

    const geoGenerator = d3.geoPath()
      .projection(projection);

    const map = d3.select(`#${mapId}`);

    const svg = map
      .append('svg')
      .attr('id', `svg-${mapId}`)
      .attr('class', `${styles.geography}`)
      .attr('width', `${width }px`)
      .attr('height', `${height }px`);

    const g = svg.append('g')
      .attr('id', 'area');

    function zoomed(e) {
      if (enableZoom) {
        g.selectAll('path')
          .style('stroke-width', `${strokeWidth / e.transform.k }px`);
        g.attr('transform', e.transform);
        g.selectAll('circle')
          .attr('r', (d) => {
            const size = d.size > 1 ? 12 : 6;
            return size / (e.transform.k);
          });
      }
    }

    const zoom = d3.zoom()
      .scaleExtent([1, scale])
    // // eslint-disable-next-line no-use-before-define
      .on('zoom', zoomed);

    g.selectAll('path')
      .data(geoData.features)
      .enter()
      .append('path')
      .attr('d', (d) => geoGenerator(d))
      .attr('id', (d) => d.properties.name_en)
      .attr('stroke-width', (p) => (p.properties.name_en === selectedCountry?.properties?.name_en ? `${0}px` : `${strokeWidth}px`))
      .attr('stroke', '#8485b5')
      .style('cursor', (d) => {
        if (d.properties.isClick === true) {
          return 'pointer';
        }
        return 'auto';
      })
      .style('opacity', (p) => {
        if (selectedCountry) {
          return p.properties.name_en === selectedCountry?.properties?.name_en ? 1 : 0;
        }
        return 1;
      })
      .attr('fill', (d) => {
        if (d?.properties?.total) {
          const opacity = opacityRange(d?.properties?.total);
          return hexToRGBA(primary, opacity);
        }
        if (d?.properties?.range) {
          const opacity = d?.properties?.range > 0
            ? opacityMaxRange(d?.properties?.range) : opacityMinRange(d?.properties?.range);
          return hexToRGBA(d?.properties.color, opacity);
        }
        return hexToRGBA('#dedee0', 0.6);
      })
      .on('mouseover', (e, d) => {
        if (d?.properties?.range) {
          setTooltipData({
            type: 'country',
            country: d.properties.name_en,
            range: d?.properties?.range,
            color: d?.properties.color,
            x: e.x - 70,
            y: e.y - 140,
          });
        }
        if (!filters.isAbs && d?.properties?.total) {
          setTooltipData({
            type: 'country',
            country: d.properties.name_en,
            value: d?.properties?.total,
            color: d?.properties.color,
            x: e.x + 70,
            y: e.y - 40,
          });
        }
      })
      .on('mouseout', (e) => setTooltipData({ x: e.x - 70, y: e.y - 140 }))
      .on('click', (e, d) => {
        if (d.properties.isClick === true) {
          return clicked(d);
        }
        return 'auto';
      });
    // .on('click', (e, d) => {
    //   if (d.properties.isClick === true) {
    //   // eslint-disable-next-line no-use-before-define
    //     clicked(e, d);
    //   }
    // });

    svg.call(zoom);
    if (markersData) {
      g.selectAll('circle')
        .data(markersData)
        .enter()
        .append('circle')
        .attr('r', 0)
        .attr('cx', (d) => projection([d.lng, d.lat])[0])
        .attr('cy', (d) => projection([d.lng, d.lat])[1])
        .style('opacity', 1)
        .attr('stroke', (d) => d.color)
        .attr('fill', (d) => d.color)
        .on('mouseover', (e, d) => {
          if (d?.products) {
            setTooltipData({
              type: 'products',
              country: d.country,
              products: d?.products,
              range: parseFloat(d?.range),
              x: e.x + 70,
              y: e.y - 40,
            });
          }
        })
        .on('mouseout', (e) => setTooltipData({ x: e.x + 70, y: e.y - 40 }))
      // eslint-disable-next-line no-use-before-define
        .on('click', (e, d) => clicked(d.feature))
        .transition()
        .delay(50)
        .duration(250)
        .attr('r', (d) => (d.size > 1 ? 12 : 6));
    }

    if (selectedCountry) {
      const country = getCountryBounds(selectedCountry);
      const bounds = geoGenerator.bounds(country);
      const dx = bounds[1][0] - bounds[0][0];
      const dy = bounds[1][1] - bounds[0][1];
      const x = (bounds[0][0] + bounds[1][0]) / 2;
      const y = (bounds[0][1] + bounds[1][1]) / 2;
      const scaleActive = 0.6 / Math.max(dx / width, dy / height);
      const translate = [width / 2 - scaleActive * x, height / 2 - scaleActive * y];

      g.selectAll('circle')
        .transition()
        .delay(0)
        .duration(250)
        .attr('r', 0)
        .style('opacity', 0);

      g.selectAll('path')
        .transition()
        .delay(50)
        .duration(250)
        .attr('stroke-width', `${0}px`)
        .style('opacity', (p) => (p.properties.name_en === selectedCountry.properties.name_en ? 1 : 0));

      svg
        .call(
          zoom.transform,
          d3.zoomIdentity.translate(translate[0], translate[1])
            .scale(scaleActive),
        );
    }

    function clicked(d = null) {
      if (!d) {
        setActiveCountry();
        selectedCountry = null;
        g.selectAll('circle')
          .transition()
          .delay(250)
          .duration(250)
          .attr('r', (c) => (c.size > 1 ? 8 : 4))
          .style('opacity', 1);

        g.selectAll('path')
          .transition()
          .delay(50)
          .duration(250)
          .attr('stroke-width', `${0.25}px`)
          .style('opacity', 1);

        svg.transition().duration(750).call(
          zoom.transform,
          d3.zoomIdentity,
          d3.zoomTransform(svg.node()).invert([width / 2, height / 2]),
        );
      } else {
        setActiveCountry(d);
        selectedCountry = d;
        const country = getCountryBounds(d);
        const bounds = geoGenerator.bounds(country);
        const dx = bounds[1][0] - bounds[0][0];
        const dy = bounds[1][1] - bounds[0][1];
        const x = (bounds[0][0] + bounds[1][0]) / 2;
        const y = (bounds[0][1] + bounds[1][1]) / 2;
        const countryScale = 0.6 / Math.max(dx / width, dy / height);
        const translate = [width / 2 - countryScale * x, height / 2 - countryScale * y];

        g.selectAll('circle')
          .transition()
          .delay(0)
          .duration(250)
          .style('opacity', 0);

        g.selectAll('path')
          .transition()
          .delay(50)
          .duration(250)
          .attr('stroke-width', `${0}px`)
          .style('opacity', (p) => (p.properties.name_en === selectedCountry.properties.name_en ? 1 : 0));

        svg.transition()
          .duration(750)
          .call(
            zoom.transform,
            d3.zoomIdentity.translate(translate[0], translate[1])
              .scale(countryScale),
          );
      }
    }

    d3.select('#back')
      .on('click', () => {
        clicked();
      });
  }

  useEffect(() => {
    if (data && mapHeight && mapWidth) {
      drawMap();
      window.addEventListener('resize', drawMap());
      return () => {
        window.removeEventListener('resize', drawMap());
      };
    }
    return null;
  }, [data, mapHeight, mapWidth]);

  const legendTitle = () => {
    if (filters.isAbs) return '€ price change';
    if (filters.yearQuarterStart) return '% change';
    return 'Value date select';
  };
  return (
    <div
      ref={mapRef}
      className={`${styles.map} ${activeCountry ? styles.country : ''}`}
      id={mapId}
      style={{ height: mapHeight, width: mapWidth }}
    >
      <div id="legend" className={`${styles.legend}`}>
        <div className={styles.item}>
          <h4>{legendTitle()}</h4>
          <p>
            {lists?.periods?.find((p) => p.value === filters.yearQuarterStart)?.label}
            {filters?.yearQuarterStart && ' - '}
            {lists?.periods?.find((p) => p.value === filters.yearQuarter)?.label}
          </p>
        </div>
        {filters?.segments.length > 0
        && (
        <div className={styles.item}>
          <h4>Treatments segments</h4>
          {filters?.segments?.map((s) => (
            <p>
              {lists?.treatmementSegments
                ?.find((pr) => pr.value === s)?.label}
            </p>
          ))}
        </div>
        )}
        {filters.vendors.length > 0
        && (
          <div className={styles.item}>
            <h4>Vendors</h4>
            {filters?.vendors?.map((s) => (
              <p>
                {lists?.vendors?.find((pr) => pr.value === s)?.label}
              </p>
            ))}
          </div>
        )}
        {filters.products.length > 0
        && (
          <div className={styles.item}>
            <h4>Products</h4>
            {filters?.products?.map((p) => (
              <div className={styles.product}>
                <div className={styles.icon} style={{ backgroundColor: getProductColor(p) }} />
                <p>{lists?.products?.find((pr) => pr.value === p)?.label}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <Tooltip data={tooltipData} />
      <CountryMapData
        activeCountry={updatedMap?.find(
          (c) => c.properties.name_en === activeCountry?.properties?.name_en,
        )}
        handleChangeQuery={(obj) => handleChangeQuery(obj)}
      />
    </div>
  );
}
