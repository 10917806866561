import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../assets/images/logo.svg';
import {
  loginAction, forgotPasswordAction, getProfileAction,
} from '../../actions/authActions';
import {
  InputEmail, InputPassword, Button, ErrorField,
} from '../../lib/HooksFormFields';
import styles from './Auth.module.scss';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userAuth, error, message } = useSelector((state) => state.auth);
  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: {
      errors,
    },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const clearError = useCallback(() => {
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  }, [dispatch]);

  const getUser = useCallback(() => {
    getProfileAction(dispatch);
  }, [dispatch]);

  function onSubmit(values) {
    setIsLoading(true);
    clearError();
    loginAction(dispatch, values);
  }

  function submitForgot() {
    clearError();
    if (!getValues('email')) {
      return setError('email', {
        type: 'manual',
        message: 'Email required',
      });
    }
    return forgotPasswordAction(dispatch, { email: getValues('email') });
  }

  useEffect(() => {
    clearError();
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [error, message]);

  useEffect(() => {
    if (userAuth?.role) {
      getUser();
      navigate('/prices/data');
    }
  }, [userAuth]);

  return (
    <div className={styles.container}>
      <div className={styles['col-form']}>
        <form>
          <div className={`${styles.title} bold`}>
            <img src={logo} alt="logo" />
            <span className={`${styles.name} bold`}>MARKET COMPARISON & ANALYSIS TOOL</span>
            <br />
            <span className="regular">Incorporating Price Tracker ByOctapharma</span>
          </div>
          <InputEmail
            name="email"
            label="Mail"
            control={control}
            className="login"
            required
            placeholder="Email"
          />
          <ErrorField message={errors.email?.message} />
          <InputPassword
            name="password"
            label="Password"
            control={control}
            className="login"
            rules={{ required: 'Password required' }}
            password
            placeholder="Password"
          />
          <ErrorField message={errors.password?.message} />
          <div className={styles['btn-container']}>
            <Button
              handleClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            >
              LOGIN
            </Button>
            <button
              type="button"
              onClick={() => submitForgot()}
              className={styles.forgot}
            >
              Forgot password ?
            </button>
          </div>
          <p className={styles.message}>
            {error === 401
              && (
              <span className={styles.error}>
                Email or password
                Incorrect.
              </span>
              )}
            {error?.data?.error === 'Your request could not be processed as entered. Please try again.'
              && (
              <span className={styles.error}>
                Email incorrect.
              </span>
              )}
            {message === 'Please check your email for the link to reset your password.'
              && (
              <span>
                Please check your email
                <br />
                for the link to reset your password.
              </span>
              )}
          </p>
        </form>
      </div>
    </div>
  );
}
