import {
  ERROR_PRICE,
  SET_PRICES_FILTERS,
  MESSAGE_PRICE,
  GET_PRICES_FILTERED,
  GET_PRICES_LISTS,
  SET_QUERY_PRICE,
  PRICE_IS_LOADING,
  SET_EXPORT_PRICE,
  GET_COUNTRIES_FILTERED,
} from '../actions/types';

export const defaultFilters = {
  geographies: [],
  businessUnits: [],
  products: [],
  segments: [],
  vendors: [],
  price_id: null,
  currency: 'euroValue',
  indicatorsProperties: [
  ],
  isAbs: false,
};

const initialState = {
  error: null,
  message: null,
  isLoading: false,
  pricesList: [],
  countriesList: [],
  count: 0,
  lists: null,
  query: '',
  exportData: null,
  filters: {
    ...defaultFilters,
    priceView: 'chart',
    yearQuarter: null,
    yearQuarterStart: null,
  },
};

export default function pricesReducer(state = initialState, action) {
  switch (action.type) {
    case PRICE_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case GET_PRICES_LISTS:
      return { ...state, lists: action.payload };
    case GET_PRICES_FILTERED:
      return {
        ...state,
        pricesList: action.payload,
        isLoading: false,
      };
    case GET_COUNTRIES_FILTERED:
      return {
        ...state,
        countriesList: action.payload,
        isLoading: false,
      };
    case SET_EXPORT_PRICE:
      return { ...state, exportData: action.payload };
    case MESSAGE_PRICE:
      return { ...state, message: action.payload };
    case ERROR_PRICE:
      return { ...state, error: action.payload };
    case SET_QUERY_PRICE:
      return { ...state, query: action.payload };
    case SET_PRICES_FILTERS:
      return { ...state, filters: action.payload };
    default:
      return { ...state };
  }
}
