import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  useWindowWidth,
} from '@react-hook/window-size';
import GlobalRouter from './Routes';
import Mobile from './pages/Mobile/Mobile';

function App() {
  const width = useWindowWidth();
  return (
    <div className="app-container">
      {width > 1024
        ? (
          <Router>
            <GlobalRouter />
          </Router>
        )
        : <Mobile />}
    </div>
  );
}

export default App;
