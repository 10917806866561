import React from 'react';
import { useSelector } from 'react-redux';
import styles from './tooltip.module.scss';

export default function Tooltip({ data }) {
  const { filters } = useSelector((state) => state.prices);
  return (
    <div
      className={`${styles.tooltip} ${styles[data.type] ? styles[data.type] : ''}`}
      style={{
        left: data.x,
        top: data.y,
        opacity: data?.type ? 1 : 0,
      }}
    >
      {data?.country && data?.type === 'country'
        && (
          <>
            <p className={styles.name}>{data.country}</p>
            <p>{data?.range ? 'value product evolution' : 'value product total'}</p>
            <p className={styles.name} style={{ color: data.color }}>
              {data?.value && (
                <span>
                  {data?.value}
                  {' '}
                </span>
              )}
              {data?.range && (
                <span>
                  {data?.range > 0 && '+'}
                  {data?.range}
                </span>
              )}
            </p>
          </>
        )}
      {data?.country && data?.type === 'products'
        && (
          <>
            <p className={styles.name}>{data.country}</p>
            {data.products.map((p) => (
              <div key={p._id} className={styles['product-card']}>
                <div className={styles.title}>
                  <p className={styles.product} style={{ color: p.color || '#192243' }}>
                    {p?.product.name}
                    {' '}
                    {p?.productRange}
                  </p>
                  <p className={styles.company}>{p?.product.vendor?.name}</p>
                </div>
                {p?.price?.euroValue
                && (
                <p className={styles.value}>
                  {p?.price?.euroValue?.toFixed(2)}
                  {' '}
                  €
                </p>
                )}
                {!filters.isAbs && !Number.isNaN(p?.range) && Number.isFinite(p?.range)
                && (
                <p className={styles.range} style={{ color: p.priceColor }}>
                  {p?.range >= 0 && '+'}
                  {p?.range?.toFixed(2)}
                  {' '}
                  %
                </p>
                )}
                {filters.isAbs && p?.abs
                && (
                <p className={styles.range} style={{ color: p.priceColor }}>
                  {p?.abs && parseFloat(p?.abs) > 0 && '+'}
                  {p?.abs}
                  {' '}
                  €
                </p>
                )}
              </div>
            ))}
          </>
        )}
    </div>
  );
}
