import {
  ERROR_FORM,
  MESSAGE_FORM,
  SET_FORM,
  POST_FORM_INDICATORS,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  indicators: [],
  lists: null,
  form: {
    source: 'input-data',
    geography: null,
    treatmentSegment: null,
    year: null,
  },
};

export default function formReducer(state = initialState, action) {
  switch (action.type) {
    case MESSAGE_FORM:
      return { ...state, message: action.payload };
    case POST_FORM_INDICATORS:
      return { ...state, indicators: action.payload };
    case SET_FORM:
      return { ...state, form: action.payload };
    case ERROR_FORM:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
