import React, { useRef } from 'react';
import {
  Outlet,
} from 'react-router-dom';
import Filters from '../../components/PricesFilters/Filters';
import Nav from '../../components/Nav';
import styles from './Prices.module.scss';

function Prices() {
  const filtersRef = useRef();
  return (
    <div className={styles.container}>
      <Nav />
      <main>
        <Filters ref={filtersRef} />
        <Outlet context={{ filtersRef }} />
      </main>
    </div>
  );
}

export default Prices;
