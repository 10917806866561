/* eslint-disable radix */
import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import styles from './IndicatorChart.module.scss';
import { thousandsSeparator } from '../../utils/utils';

function Tooltip(d) {
  const { point } = d;
  const { serieColor, data } = point;
  return (
    <div
      style={{
        padding: 8,
        marginTop: 20,
        color: '#A1A0AF',
        alignItems: 'center',
        fontFamily: 'Poppins-Regular',
        background: '#FFFFFF',
        borderRadius: 3,
      }}
    >
      <p style={{ color: serieColor, margin: 0, fontWeight: 'bold' }}>
        {data.name}
      </p>
      <strong>
        <span style={{ color: '#415B94' }}>
          <span style={{ opacity: 0.5 }}>{data.x}</span>
          <br />
          {data?.type && <span style={{ color: data.color }}>{data.type}</span>}
          <br />
          {' '}
          {' '}
          {data.y.toFixed(2)}
          {' '}
          {data.unit}
        </span>
        <br />
      </strong>
    </div>
  );
}

function IndicatorChart({
  data = [], id, color, currencyCode, forcast,
}) {
  const dataSet = [{ id, color: '#415B94', data: data?.map((d) => ({ ...d, x: d.period, y: d.value })) }];

  const datasetForcast = [
    {
      id: `actual${id}`,
      color: '#BFD5F1',
      data: data?.map((d) => ({
        ...d,
        type: 'Actual',
        color: '#BFD5F1',
        x: d.period,
        y: parseInt(d.period) <= parseInt(forcast) ? d.value : null,
      })),
    },
    {
      id: `forcast${id}`,
      color: '#041483',
      data: data?.map((d) => ({
        ...d,
        type: 'Forcast',
        color: '#041483',
        x: d.period,
        y: parseInt(d.period) >= parseInt(forcast) ? d.value : null,
      })),
    },
  ];

  const theme = {
    grid: {
      line: {
        stroke: '#A8A8AB',
        strokeDasharray: '4 4',
      },
    },
    axis: {
      ticks: {
        line: {
          stroke: 'transparent',
        },
        text: {
          fill: '#A8A8AB',
        },
      },
    },
  };
  function getColor(d) {
    if (d.color) return d.color;
    return '#BA3783';
  }

  const values = data?.filter(
    (v) => (v.value ? v : null),
  ).map((v) => v.value.toFixed(2));
  const max = Math.max(...values);
  const min = Math.min(...values);
  const tickValues = values ? [min, max] : null;
  const countNumbers = thousandsSeparator(max)?.length;

  return (
    <div className={styles.container}>
      <div className={styles.currency}>
        {currencyCode}
      </div>
      <div className={styles.chart}>
        <ResponsiveLine
          data={forcast ? datasetForcast : dataSet}
          margin={{
            top: 20,
            right: 10,
            bottom: 28,
            left: color ? ((countNumbers) * 6) + 18 : ((countNumbers) * 6) + 10,
          }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={values?.length > 0 && {
            format: (value) => {
              if (!color) {
                return thousandsSeparator(value);
              }
              if (value < 10 && !Number.isInteger(value)) {
                return value.toFixed(2);
              }
              return value.toFixed(0);
            },
            orient: 'left',
            tickValues,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          theme={theme}
          tooltip={(d) => Tooltip(d)}
          enableGridX={false}
          enablePoints
          pointSize={3}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          colors={(d) => getColor(d)}
          pointLabelYOffset={-15}
          enableCrosshair={false}
          useMesh
          legends={[]}
        />

      </div>
      <div className={styles.labels}>
        <p className={styles.label}>{data[0]?.period}</p>
        <p className={styles.label}>{data[data.length - 1]?.period}</p>
      </div>
    </div>
  );
}

export default IndicatorChart;
