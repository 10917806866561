import axios from 'axios';

export function setVhUnit() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

export function hexToRGBA(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${ r }, ${ g }, ${ b }, ${ alpha })`;
  }
  return `rgb(${ r }, ${ g }, ${ b })`;
}

export function calcPercentage(a, b) {
  const decreaseValue = ((b - a) / a) * 100;
  // const decreaseValue = (1 - (a / b)) * 100;
  return decreaseValue;
}

export function getCountryBounds(d) {
  const country = { ...d };
  // console.log(country);
  if (d.properties.iso_a3 === 'FRA' && country.geometry.coordinates.length === 3) {
    country.geometry.coordinates = [
      country.geometry.coordinates[1],
      country.geometry.coordinates[2],
    ];
  }
  if (d.properties.iso_a3 === 'RUS' && country.geometry.coordinates.length !== 1) {
    country.geometry.coordinates = [
      country.geometry.coordinates[1],
    ];
  }
  return country;
}

export function getPriceRate(productId, countryCode, data, currency = 'euroValue') {
  const productsList = data
    .filter((pr) => pr.product._id === productId && pr.geography.code === countryCode)
    .sort((a, b) => b.price.yearQuarter > a.price.yearQuarter);
  const pricesLists = productsList.map((v) => v.price[currency]);
  const min = pricesLists[0];
  const max = pricesLists[pricesLists.length - 1];
  const value = calcPercentage(min, max);
  return {
    value,
    productsList,
  };
}

export function getPriceAbs(productId, countryCode, data, currency = 'euroValue') {
  const productsList = data
    .filter((pr) => pr.product._id === productId && pr.geography.code === countryCode)
    .sort((a, b) => b.price.yearQuarter > a.price.yearQuarter);
  const pricesLists = productsList.map((v) => v.price[currency]);
  const min = pricesLists[0];
  const max = pricesLists[pricesLists.length - 1];
  const value = max - min;
  return {
    value,
    productsList,
  };
}

export async function getPopulationByKey(code) {
  const url = `https://restcountries.com/v3.1/alpha/${code}?fields=population`;
  const response = await axios.get(url);
  if (response?.data) {
    const { population } = response.data;
    return (population / 1000000).toFixed(2);
  }
  throw new Error(`HTTP error! Status: ${response.status}`);
}

export function thousandsSeparator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
