import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import styles from './input-email.module.scss';

function InputEmail({
  name,
  control,
  required = false,
  widthLabel = null,
  inline = false,
  label = null,
  placeholder = '',
  className,
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? 'This email field is required' : false,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: 'Please enter a valid email address',
        },
      }}
      render={({
        field: {
          // name,
          value = '',
          ref,
          onChange,
          onBlur,
        },
      }) => (
        <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input-email']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input-email']}`}>
          { label && (
          <label
            htmlFor={name}
            style={widthLabel ? { minWidth: widthLabel } : {}}
          >
            {label}
          </label>
          )}
          <input
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.value)}
            className={styles.input}
            type="email"
            placeholder={placeholder}
          />
        </div>
      )}
    />
  );
}

InputEmail.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default InputEmail;
