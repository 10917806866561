// AUTH ACTIONS
export const ERROR_AUTH = 'ERROR_AUTH';
export const USER_AUTH = 'USER_AUTH';
export const MESSAGE_AUTH = 'MESSAGE_AUTH';
export const LOGOUT = 'LOGOUT';

// PRICE ACTIONS
export const SET_PRICES_FILTERS = 'SET_PRICES_FILTERS';
export const GET_PRICES_FILTERED = 'GET_PRICES_FILTERED';
export const GET_COUNTRIES_FILTERED = 'GET_COUNTRIES_FILTERED';
export const EXPORT_COUNTRIES_FILTERED = 'EXPORT_COUNTRIES_FILTERED';
export const GET_PRICES_LISTS = 'GET_PRICES_LISTS';
export const SET_QUERY_PRICE = 'SET_QUERY_PRICE';
export const PRICE_IS_LOADING = 'PRICE_IS_LOADING';
export const ERROR_PRICE = 'ERROR_PRICE';
export const MESSAGE_PRICE = 'MESSAGE_PRICE';
export const SET_EXPORT_PRICE = 'SET_EXPORT_PRICE';

// // GLOBALS ACTIONS
export const ERROR_FORM = 'ERROR_FORM';
export const MESSAGE_FORM = 'MESSAGE_FORM';
export const SET_FORM = 'SET_FORM';
export const GET_FORM_LIST = 'GET_FORM_LIST';
export const GET_FORM_INDICATORS = 'GET_FORM_INDICATORS';
export const POST_FORM_INDICATORS = 'POST_FORM_INDICATORS';
