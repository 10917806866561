import React from 'react';
import PropTypes from 'prop-types';
import styles from './button.module.scss';

function Button({
  children,
  type,
  isLoading,
  handleClick,
  className = '',
  isDisabled = false,
}) {
  return (
    <button type="button" onClick={handleClick} className={`${styles.button} ${styles[className]} ${isDisabled ? styles.disabled : ''} ${styles[type]} ${isLoading ? styles.loading : ''}`}>
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  handleClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'primary',
  isLoading: false,
  handleClick: () => {},
};

export default Button;
