import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { getProfileAction, logoutAction } from './actions/authActions';

import { setVhUnit } from './utils/utils';
import Loader from './components/Loader';

// AUTH
import Login from './pages/Auth/Login';
import Reset from './pages/Auth/Reset';
import Geography from './pages/Geography/Geography';
import Data from './pages/Data/Data';
import Prices from './pages/Prices';
import InputData from './pages/InputData/InputData';
// import Forgot from './pages/Auth/Forgot';
// import Reset from './pages/Auth/Reset';

function Redirect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userAuth } = useSelector((state) => state.auth);
  useEffect(() => {
    setVhUnit();
    const token = localStorage.getItem('token');
    if (token) getProfileAction(dispatch);
    else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (userAuth?.role) {
      navigate('/prices/geography');
    }
  }, [userAuth]);

  return <Loader />;
}

function AppRoutes() {
  const { userAuth, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (error === "Vous n'êtes pas autoriser à accéder à ce contenu" || error === 401) {
      console.log(error);
      logoutAction(dispatch);
      navigate('/login');
    }
  }, [error]);
  return (
    <Routes>
      <Route path="/" element={<Redirect />} />
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password/:token" element={<Reset />} />
      {userAuth?.role && (
        <>
          <Route path="/prices" element={<Prices />}>
            <Route path="/prices/geography" element={<Geography />} />
            <Route path="/prices/data" element={<Data />} />
          </Route>
          <Route path="/form" element={<InputData />} />
        </>
      )}
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
}

export default AppRoutes;
