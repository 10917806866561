import {
  ERROR_AUTH,
  USER_AUTH,
  MESSAGE_AUTH,
  LOGOUT,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  userAuth: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      return { ...state, userAuth: null };
    case MESSAGE_AUTH:
      return { ...state, message: action.payload };
    case ERROR_AUTH:
      return { ...state, error: action.payload };
    case USER_AUTH:
      return { ...state, userAuth: action.payload };
    default:
      return { ...state };
  }
}
