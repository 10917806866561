import React, { useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import Map from '../../components/Map/Map';
import styles from './Geography.module.scss';

export default function Geography() {
  const getProductColor = useOutletContext()?.filtersRef?.current?.getProductColor;
  const handleChangeQuery = useOutletContext()?.filtersRef?.current?.handleChangeQuery;
  const {
    pricesList, filters, isLoading, lists,
  } = useSelector((state) => state.prices);

  const containerRef = useRef();

  return (
    <div ref={containerRef} className={styles.container}>
      {containerRef?.current?.clientHeight
        && (
        <Map
          data={pricesList}
          filters={filters}
          lists={lists}
          isLoading={isLoading}
          getProductColor={getProductColor}
          enableZoom={!isLoading}
          handleChangeQuery={handleChangeQuery}
          mapWidth={containerRef?.current?.clientWidth}
          mapHeight={containerRef?.current?.clientHeight}
          mapId="geography"
        />
        )}
      {lists && isLoading && <Loader />}
    </div>
  );
}
