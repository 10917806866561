import React from 'react';
import styles from './error-field.module.scss';

function ErrorField({ message = '' }) {
  return (
    <div className={styles['errors-fields']}>
      <p>{message}</p>
    </div>
  );
}

export default ErrorField;
