import React from 'react';
import { useSelector } from 'react-redux';
import styles from './VendorsIndicators.module.scss';

export default function VendorsIndicators({ segement }) {
  const { name, data } = segement;
  const { lists } = useSelector((state) => state.prices);
  const color = lists?.treatmementSegments?.find((t) => t.label === name)?.color;
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {color && <div className={styles.puce} style={{ backgroundColor: color }} />}
        <h5>{name}</h5>
      </div>
      {data.map((s) => (
        <div key={s._id} className={styles['sub-segment']}>
          <h6>
            {s.name}
          </h6>
          <p className={styles.source}>
            source:
            {' '}
            {s.source}
          </p>
          {s.data.map((l) => (
            <div key={l._id} className={styles['label-data']}>
              <p>{l.name}</p>
              {l?.vendors?.map((v) => (
                <div key={v._id} className={styles.vendor}>
                  <div className={styles.icon} style={{ backgroundColor: v.color === '#FFFFFF' ? '#B9B9BA' : v.color }} />
                  <p>{v.name}</p>
                  <div className={`
                    ${styles.value}
                    ${v.unit === '%' && v.value > 0 ? styles.success : ''}
                    ${v.unit === '%' && v.value < 0 ? styles.danger : ''}
                    `}
                  >
                    <p>
                      {v.value.toFixed(2)}
                      {' '}
                      {v.unit && v.unit}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
