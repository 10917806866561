/* eslint-disable react/no-danger */
import React, {
  useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { IoChevronDown } from 'react-icons/io5';
import styles from './CountryData.module.scss';
import IndicatorChart from '../IndicatorChart/IndicatorChart';
import { getPopulationByKey } from '../../utils/utils';
import VendorsIndicators from '../VendorsIndicators/VendorsIndicators';
import GroupedProducts from '../GroupedProducts/GroupedProducts';

function Indicator({ indicator }) {
  if (indicator?.value) {
    return (
      <div className={styles.indicator}>
        <p className={styles.label}>{indicator?.label}</p>
        {indicator?.label === 'Comment'
          ? <div className={styles.comment} dangerouslySetInnerHTML={{ __html: indicator.value }} />
          : (
            <p>
              {indicator.value}
              {' '}
              {indicator.unit}
            </p>
          )}
        {indicator.sourceType
          ? (
            <p className={styles.source}>
              source:
              {' '}
              {indicator.sourceType}
            </p>
          ) : (
            <p className={styles.source}>source: MRB</p>

          )}
      </div>
    );
  }
  return (
    <div className={styles.indicator}>
      <p className={styles.label}>{indicator?.label}</p>
      <div className={styles['chart-container']}>
        <IndicatorChart
          data={indicator.data || []}
          forcast={indicator?.forcast}
          id={indicator.label}
        />
      </div>
      {indicator.sourceType
      && (
        <p className={styles.source}>
          source:
          {' '}
          {indicator.sourceType}
        </p>
      )}
    </div>
  );
}

function ProductIndicator({
  product, currency, currencyCode, id,
}) {
  const currencyValue = product.data.find((d) => d.currency)?.currency;
  return (
    <div className={styles.indicator}>
      <p className={styles.label}>
        <span
          className={styles.puce}
          style={{ backgroundColor: product?.vendor?.color }}
        />
        {product?.name}
      </p>
      <div className={styles['chart-container']}>
        <IndicatorChart
          data={product.data.map((d) => ({ ...d, value: d[currency], unit: currency === 'euroValue' ? '€' : currencyValue })) || []}
          id={id}
          currencyCode={currencyCode}
          color={product?.vendor?.color}
        />
      </div>
      <p className={styles.source}>
        source:
        {' '}
        {product.sourceType}
      </p>
    </div>
  );
}

export default function CountryData({
  country,
  getProductColor,
  indicatorIsOpen,
  setIndicatorIsOpen,
  vendorsIsOpen,
  setVendorsIsOpen,
  pricesIsOpen,
  setPricesIsOpen,
  handleChangeCurrency,
  handleChangePriceView,
  width,
}) {
  const { filters } = useSelector((state) => state.prices);

  const indicatorRef = useRef();
  const vendorsRef = useRef();
  const pricesRef = useRef();
  const [height, setHeight] = useState(0);
  const [vendorsHeight, setVendorsHeight] = useState(0);
  const [pricesHeight, setPricesHeight] = useState(0);
  const [population, setPopulation] = useState(null);

  const indicatorHeight = indicatorRef?.current?.getBoundingClientRect()?.height || 0;
  const vendorsHeightValue = vendorsRef?.current?.getBoundingClientRect()?.height || 0;
  const pricesHeightValue = pricesRef?.current?.getBoundingClientRect()?.height || 0;

  async function fetchPop() {
    const pop = await getPopulationByKey(country.code);
    setPopulation(pop);
  }

  useEffect(() => {
    if (country?.code && country?.region?.value) {
      fetchPop();
    }
  }, [country]);

  useEffect(() => {
    setHeight(indicatorHeight);
    setVendorsHeight(vendorsHeightValue);
    setPricesHeight(pricesHeightValue);
  }, [
    indicatorHeight,
    vendorsHeightValue,
    pricesHeightValue,
  ]);

  return (
    <div className={styles.container} style={{ width, minWidth: width }}>
      <div className={styles.header}>
        <div className={styles.title}>
          <h4>
            {country?.name}
          </h4>

          <p>
            {country?.region?.value
              && (
              <>
                {country?.region?.value}
                {' '}
                /
                {' '}
                {country?.currency?.value}
              </>
              )}
          </p>

          <button
            type="button"
            onClick={() => setIndicatorIsOpen(!indicatorIsOpen)}
            className={`${styles.toggle} ${indicatorIsOpen ? styles.active : ''}`}
          >
            <IoChevronDown />
          </button>
        </div>
        <div className={styles.info}>
          {country?.region?.value
            && (
            <>
              <div className={styles.col}>
                <p>Population (External Source)</p>
                <h4>
                  {population}
                  {' '}
                  MM
                </h4>
              </div>
              <div className={styles.col}>
                <p>Board member</p>
                <h4>
                  {country?.boardMember?.value}
                </h4>
              </div>
            </>
            )}
        </div>
      </div>
      <div className={styles['indicators-container']} style={{ height: indicatorIsOpen ? height : 0 }}>
        <div ref={indicatorRef} className={styles.indicators}>
          {country?.indicators.map((indicator) => (
            <Indicator
              key={`${country?.name}-${indicator?.label}`}
              indicator={indicator}
            />
          ))}
        </div>
      </div>
      {country?.region?.value && country?.products.length > 0
      && (
      <div className={styles['list-container']}>
        <div className={styles.title}>
          <h4>Prices</h4>
          <button
            type="button"
            onClick={() => setPricesIsOpen(!pricesIsOpen)}
            className={`${styles.toggle} ${pricesIsOpen ? styles.active : ''}`}
          >
            <IoChevronDown />
          </button>
        </div>
        <div
          className={styles.prices}
          style={{ height: pricesIsOpen ? pricesHeight + 30 : 0 }}
        >
          <div
            ref={pricesRef}
            className={styles.content}
          >
            <div className={styles.toggles}>
              <div className={styles.currencies}>
                <button
                  type="button"
                  className={filters?.currency === 'euroValue' ? styles.active : ''}
                  onClick={() => handleChangeCurrency('euroValue')}
                >
                  Euro
                </button>
                <button
                  type="button"
                  className={filters?.currency === 'localValue' ? styles.active : ''}
                  onClick={() => handleChangeCurrency('localValue')}
                >
                  Local currency
                </button>
              </div>
              <div className={styles.view}>
                <button
                  type="button"
                  className={filters?.priceView === 'chart' ? styles.active : ''}
                  onClick={() => handleChangePriceView('chart')}
                >
                  Grouped
                </button>
                <button
                  type="button"
                  className={filters?.priceView === 'list' ? styles.active : ''}
                  onClick={() => handleChangePriceView('list')}
                >
                  List
                </button>
              </div>
            </div>
            {filters?.priceView === 'list' && country?.products?.map((p) => (
              <ProductIndicator
                key={`${country.name}-${p?._id}`}
                product={p}
                id={`${country.name}-${p?._id}`}
                currency={filters?.currency}
                currencyCode={filters.currency === 'localValue' ? country?.currency?.value : 'Euro'}
                color={getProductColor(p?._id)}
              />
            ))}
            {filters?.priceView === 'chart'
              && (<GroupedProducts products={country.products} country={country} />)}
          </div>
        </div>
      </div>
      )}
      {country?.vendors.length > 0
        && (
        <div className={styles['list-container']}>
          <div className={styles.title}>
            <h4>Vendors</h4>
            <button
              type="button"
              onClick={() => setVendorsIsOpen(!vendorsIsOpen)}
              className={`${styles.toggle} ${vendorsIsOpen ? styles.active : ''}`}
            >
              <IoChevronDown />
            </button>
          </div>
          <div
            className={styles.vendors}
            style={{ height: vendorsIsOpen ? vendorsHeight + 30 : 0 }}
          >
            <div ref={vendorsRef}>
              {country.vendors.map((s) => <VendorsIndicators key={s._id} segement={s} />)}
            </div>
          </div>
        </div>
        )}
    </div>
  );
}
