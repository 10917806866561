import React, { useState, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import imgProduct from '../../assets/images/search.svg';
import Loader from '../../components/Loader';
import styles from './Data.module.scss';
import GroupedSelect from '../../components/GroupedSelect/GroupedSelect';
import CountryData from '../../components/CountryData/CountryData';

export default function Data() {
  const {
    filters, isLoading, lists, countriesList,
  } = useSelector((state) => state.prices);
  const { filtersRef } = useOutletContext();
  const countriesRef = useRef();
  const [countryWidth, setCountryWidth] = useState(0);
  const [indicatorIsOpen, setIndicatorIsOpen] = useState(true);
  const [vendorsIsOpen, setVendorsIsOpen] = useState(true);
  const [pricesIsOpen, setPricesIsOpen] = useState(true);

  const countriesCount = countriesList?.length || 0;

  useLayoutEffect(() => {
    const containerWidth = countriesRef?.current?.clientWidth || 0;
    const width = containerWidth / countriesCount;
    if (countriesCount > 0) {
      if (width > 330) {
        setCountryWidth(width - 20);
      } else {
        setCountryWidth(330);
      }
    }
  }, [countriesCount, countriesRef?.current]);

  return (
    <div className={styles.container}>
      {lists && !isLoading && filters.geographies?.length === 0 && (
      <div className={styles['add-product']}>
        <img src={imgProduct} alt="choose-product" />
        <div className={styles.filters}>
          <p className={styles.text}>To start exploring your data, add a country.</p>
          <div className={styles.select}>
            <div className={styles.box}>
              <GroupedSelect
                options={lists.geographies}
                // eslint-disable-next-line max-len
                handleChangeQuery={(value) => filtersRef.current.handleChangeQuery({ geographies: value })}
                selected={filters.geographies}
                label="+ Add a country"
              />
            </div>
          </div>
        </div>
      </div>
      )}
      {isLoading && <Loader /> }
      {filtersRef.current
        && !isLoading && filters.geographies?.length > 0
        && (
          <div
            className={styles.countries}
            ref={countriesRef}
          >
            {countriesList?.map((c) => (
              <CountryData
                key={c._id}
                country={c}
                width={countryWidth}
                getProductColor={(id) => filtersRef.current.getProductColor(id)}
                handleChangeCurrency={(value) => filtersRef?.current?.handleChangeQuery(
                  { currency: value },
                )}
                handleChangePriceView={(value) => filtersRef?.current?.handleChangeQuery(
                  { priceView: value },
                )}
                indicatorIsOpen={indicatorIsOpen}
                setIndicatorIsOpen={setIndicatorIsOpen}
                vendorsIsOpen={vendorsIsOpen}
                setVendorsIsOpen={setVendorsIsOpen}
                pricesIsOpen={pricesIsOpen}
                setPricesIsOpen={setPricesIsOpen}
              />
            ))}
          </div>
        )}
    </div>
  );
}
