import React from 'react';
import styles from './Mobile.module.scss';

export default function Mobile() {
  return (
    <div className={styles.container}>
      <p>
        Application not available
        <br />
        {' '}
        for your device
      </p>
    </div>
  );
}
