import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../reducers/authReducer';
import pricesReducer from '../reducers/pricesReducer';
import formReducer from '../reducers/formReducer';

const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: { warnAfter: 128 },
    serializableCheck: { warnAfter: 128 },
  }),
  reducer: {
    auth: authReducer,
    prices: pricesReducer,
    form: formReducer,
  },
});

export default store;
