import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryIndicatorsAction, updateCountryIndicatorsAction } from '../../actions/countryFormActions';
import { getPricesListsAction } from '../../actions/pricesActions';
import { SET_FORM } from '../../actions/types';
import CountryForm from '../../components/CountryForm/CountryForm';
import Nav from '../../components/Nav';
import Select from '../../components/Select';
import styles from './InputData.module.scss';

export default function InputData() {
  const dispatch = useDispatch();
  const { lists } = useSelector((state) => state.prices);
  const { form, indicators } = useSelector((state) => state.form);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isDisabled = !form.treatmentSegment || !form.year || !form.geography;
  const {
    watch,
    setValue,
    reset,
    control,
    handleSubmit,
  } = useForm();

  const watchForm = watch();
  const getLists = useCallback(() => {
    getPricesListsAction(dispatch);
  }, [dispatch]);

  function handleChangeForm(obj) {
    dispatch({
      type: SET_FORM,
      payload: {
        ...form,
        ...obj,
      },
    });
  }

  function onSubmit(value) {
    if (isDisabled) {
      return;
    }
    const updatedIndicators = [];

    if (value?.marketSizeValue) {
      const indicator = { ...form };
      let inputValue = value.marketSizeValue;
      if (typeof inputValue === 'string') {
        inputValue = parseFloat(value.marketSizeValue.split(' ').join(''));
      }
      indicator.value = inputValue;
      indicator.label = 'Market size value';
      indicator.type = 'marketSizeValue';
      if (value.currency) {
        indicator.unit = value.currency.label;
      }
      updatedIndicators.push(indicator);
    }
    if (value?.marketSizeQuantity) {
      const indicator = { ...form };
      let inputValue = value?.marketSizeQuantity;
      if (typeof inputValue === 'string') {
        inputValue = parseFloat(value?.marketSizeQuantity.split(' ').join(''));
      }
      indicator.value = inputValue;
      indicator.label = 'Market size Quantity';
      indicator.type = 'marketSizeQuantity';
      if (value.unit) {
        indicator.unit = value.unit.label;
      }
      updatedIndicators.push(indicator);
    }
    if (value.marketPotential) {
      const indicator = { ...form };
      indicator.content = value.marketPotential.label;
      indicator.label = 'Market potential';
      indicator.type = 'marketPotential';
      updatedIndicators.push(indicator);
    }
    if (value.comment) {
      const indicator = { ...form };
      indicator.content = value.comment;
      indicator.label = 'Comment';
      indicator.type = 'comment';
      updatedIndicators.push(indicator);
    }
    if (value.informationSource) {
      const indicator = { ...form };
      indicator.content = value.informationSource.label;
      indicator.label = 'Source of information';
      indicator.type = 'informationSource';
      updatedIndicators.push(indicator);
    }
    console.log(updatedIndicators, form);
    setIsLoading(true);
    updateCountryIndicatorsAction(dispatch, { indicators: updatedIndicators, query: form });
  }

  useEffect(() => {
    getLists();
  }, []);

  useEffect(() => {
    if (form.year) {
      reset();
      setIsLoading(false);
    }
    if (form.geography && form.treatmentSegment) {
      getCountryIndicatorsAction(dispatch, { query: form });
      setIsLoading(true);
    }
  }, [form.year, form.geography, form.treatmentSegment]);

  useEffect(() => {
    if (indicators && lists) {
      const data = {};
      const years = [...yearsOptions];
      indicators
        .map((i) => i.year)
        .sort((a, b) => parseFloat(a) - parseFloat(b))
        .forEach((i) => {
          if (!years.find((y) => y === i)) {
            years.push(i);
          }
        });
      setYearsOptions(years);
      indicators.filter((y) => y.year === form.year).forEach((i) => {
        if (i.type === 'marketSizeValue') {
          data[i.type] = i.value;
          const currency = lists.currencies.find((d) => d.label === i.unit);
          if (currency) {
            data.currency = currency;
          }
        }
        if (i.type === 'marketSizeQuantity') {
          data[i.type] = i.value;
          const unit = lists.units.find((d) => d.label === i.unit);
          if (unit) {
            data.unit = unit;
          }
        }
        if (i.type === 'comment') {
          data[i.type] = i.content;
        }
        if (i.type === 'marketPotential') {
          const potential = lists.potentials.find((d) => d.label === i.content);
          if (potential) {
            data[i.type] = potential;
          }
        }
        if (i.type === 'informationSource') {
          const source = lists.sources.find((d) => d.label === i.content);
          if (source) {
            data[i.type] = source;
          }
        }
      });
      reset(data);
      setIsLoading(false);
    }
  }, [indicators, lists, form.year]);

  return (
    <div className={styles.container}>
      <Nav />
      {lists
      && (
      <main>
        <div className={styles.selects}>
          <div className={`${styles.box}`}>
            <Select
              options={lists.countries}
              handleChangeQuery={(value) => handleChangeForm({ geography: value[0] })}
              isMulti={false}
              selected={form.geography ? [form.geography] : []}
              closeAfterChange
              label={form.geography ? lists.countries.find((g) => g.value === form.geography)?.label : 'Country'}
            />
          </div>
          <div className={`${styles.box}`}>
            <Select
              options={lists.treatmementSegments}
              isMulti={false}
              handleChangeQuery={(value) => handleChangeForm({ treatmentSegment: value[0] })}
              selected={form.treatmentSegment ? [form.treatmentSegment] : []}
              closeAfterChange
              label={form.treatmentSegment ? lists.treatmementSegments.find((g) => g.value === form.treatmentSegment)?.label : 'Treatments segments'}
            />
          </div>
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            className={`${styles.submit} ${isDisabled ? styles.disabled : ''}`}
          >
            Update data
          </button>
        </div>

        <div className={styles.form}>
          {watchForm
          && (
          <CountryForm
            years={yearsOptions}
            setYearsOptions={(array) => setYearsOptions(array)}
            handleChangeForm={(value) => handleChangeForm({ year: value })}
            control={control}
            setIsLoading={setIsLoading}
            isLoading={isLoading || isDisabled}
            setValue={setValue}
            watchForm={watchForm}
          />
          )}
        </div>
      </main>
      )}
    </div>
  );
}
