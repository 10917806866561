import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { GrFormView, GrFormViewHide } from 'react-icons/gr';
import styles from './input-password.module.scss';

function InputPassword({
  name,
  control,
  required,
  widthLabel = null,
  inline = false,
  label = null,
  requiredMessage = '',
  placeholder,
  className,
}) {
  const [type, setType] = useState('password');

  function getRequiredMessage() {
    return requiredMessage || 'This password field is required';
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? getRequiredMessage() : false,
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^/\\+\-|=!*?()@%&]).{8,}$/,
          message: 'The password must have at least 8 characters, in numbers, in capitals and a special character',
        },
      }}
      render={({
        field: {
          // name,
          value = '',
          ref,
          onChange,
          onBlur,
        },
      }) => (
        <div className={inline ? `${className ? styles[className] : ''}  ${styles['container-input-password']} ${styles.inline}` : `${className ? styles[className] : ''}  ${styles['container-input-password']}`}>
          { label && (
          <label
            htmlFor={name}
            style={widthLabel ? { minWidth: widthLabel } : {}}
          >
            {label}
          </label>
          )}
          <div className={styles['container-input']}>
            <input
              ref={ref}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              className={styles.input}
              placeholder={placeholder}
              type={type}
              autoComplete="on"
            />
            <button
              type="button"
              className={styles['container-icon']}
              onClick={() => setType((t) => (t === 'password' ? 'text' : 'password'))}
            >
              {type === 'password'
                ? <GrFormView size={30} color="#FFFFFF" /> : <GrFormViewHide size={30} color="#FFFFFF" />}
            </button>
          </div>
        </div>

      )}
    />

  );
}

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default InputPassword;
