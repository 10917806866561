import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCountryIndicatorsAction } from '../../actions/countryFormActions';
import { SET_FORM } from '../../actions/types';
import { ErrorField, InputFormatNumber, StyledSelect } from '../../lib/HooksFormFields';
import HtmlEditor from '../../lib/HooksFormFields/HtmlEditor/HtmlEditor';
import styles from './CountryForm.module.scss';

function SelectYears({
  years,
  setYearsOptions,
  handleChangeForm,
  form,
  setIsLoading,
}) {
  const [isNewYear, setIsNewYear] = useState(false);
  const [newYear, setNewYear] = useState('');

  function handleChange(year) {
    setIsLoading(true);
    handleChangeForm(year);
    setIsNewYear(false);
  }

  function addYear() {
    if (years.length === 0) {
      handleChange(newYear);
    }
    setYearsOptions([...years, newYear]);
  }

  return (
    <div className={styles['select-years']}>
      <div className={styles.options}>
        {years.map((y) => (
          <button
            key={`year-${y}`}
            type="button"
            onClick={() => handleChange(y)}
            className={y === form.year ? styles.active : ''}
          >
            {y}
          </button>
        ))}
      </div>
      {!isNewYear
      && (
        <button
          type="button"
          onClick={() => setIsNewYear(true)}
          className={styles.add}
        >
          Add a year
        </button>
      )}
      {isNewYear
      && (
        <>
          <input
            value={newYear}
            type="text"
            placeholder={new Date().getFullYear()}
            onChange={(e) => setNewYear(e.target.value)}
          />
          {newYear.length === 4 && !years.find((y) => y === newYear)
          && (
          <button
            type="button"
            onClick={() => addYear()}
            className={`${styles.add}`}
          >
            Add this year
          </button>
          )}
        </>
      )}
    </div>
  );
}

export default function CountryForm({
  years,
  setYearsOptions,
  handleChangeForm,
  setValue,
  control,
  setIsLoading,
  isLoading,
  watchForm,
}) {
  const dispatch = useDispatch();
  const { form, indicators } = useSelector((state) => state.form);
  const { lists } = useSelector((state) => state.prices);

  function getErrorMessage() {
    let message = null;
    if (!form.year) {
      message = 'The year is required';
    }
    if (!form.treatmentSegment) {
      message = 'The treatment segment is required';
    }
    if (!form.geography) {
      message = 'The country is required';
    }
    if (message) {
      return (
        <div className={styles.error}>
          <ErrorField message={message} />
        </div>
      );
    }
    return null;
  }

  function deleteYear() {
    setYearsOptions(years.filter((y) => y !== form.year));
    deleteCountryIndicatorsAction(dispatch, { query: form });
    dispatch({
      type: SET_FORM,
      payload: { ...form, year: null },
    });
  }

  return (
    <div className={styles.container}>
      <SelectYears
        years={years}
        setYearsOptions={setYearsOptions}
        handleChangeForm={handleChangeForm}
        setIsLoading={setIsLoading}
        form={form}
      />
      {getErrorMessage()}
      {!isLoading
      && (
        <div className={styles.form}>
          <div className={styles.inputs}>
            <div className={styles.row}>
              <div className={styles.field}>
                <InputFormatNumber
                  name="marketSizeValue"
                  label="Market size value"
                  control={control}
                  maxLength={200}
                />
              </div>
              <div className={styles.field}>
                <StyledSelect
                  name="currency"
                  label="Currency"
                  control={control}
                  options={lists.currencies}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <InputFormatNumber
                  name="marketSizeQuantity"
                  label="Market size quantity"
                  control={control}
                  maxLength={200}
                />
              </div>
              <div className={styles.field}>
                <StyledSelect
                  name="unit"
                  label="Unit"
                  control={control}
                  options={lists.units}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <StyledSelect
                  name="marketPotential"
                  label="Market potential"
                  control={control}
                  options={lists.potentials}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <StyledSelect
                  name="informationSource"
                  label="Source of information"
                  control={control}
                  options={lists.sources}
                />
              </div>
            </div>
          </div>
          <div className={styles.comment}>
            <p className={styles.label}>Comment</p>
            {!isLoading && watchForm.comment
            && (
            <HtmlEditor
              onChange={(html) => setValue('comment', html)}
              defaultValue={watchForm.comment}
            />
            )}
            {!isLoading && !watchForm.comment
            && (
            <HtmlEditor
              onChange={(html) => setValue('comment', html)}
              defaultValue="<p></p>"
            />
            )}
          </div>
        </div>
      )}
      {indicators.find((i) => i.year === form.year)
        && (
        <button
          type="button"
          onClick={() => deleteYear()}
          className={styles.delete}
        >
          Delete this year
        </button>
        )}
    </div>
  );
}
