import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { exportIndicatorsAction } from '../../actions/pricesActions';
import { Button, StyledSelect } from '../../lib/HooksFormFields';
import styles from './ExportModal.module.scss';

export default function ExportModal({ close }) {
  const {
    lists, filters,
  } = useSelector((state) => state.prices);
  const dispatch = useDispatch();

  const {
    control,
    watch,
    reset,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState({
    geographies: [],
    treatmementSegments: [],
    products: [],
  });

  async function onSubmit() {
    const values = watch();
    setIsLoading(true);
    const file = await exportIndicatorsAction(dispatch, values);
    if (file) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (lists) {
      const optionsLists = {
        geographies: [],
        treatmementSegments: [],
        products: lists.products,
        indicatorsProperties: lists.indicatorsProperties,
      };
      lists.geographies.forEach((geo) => {
        optionsLists.geographies.push({ label: geo.label, value: geo.value });
        geo.lists.forEach((c) => {
          optionsLists.geographies.push({ label: c.label, value: c.value });
        });
      });
      lists.treatmementSegments.forEach((seg) => {
        optionsLists.treatmementSegments.push({ label: seg.label, value: seg.value });
      });
      setOptions(optionsLists);
    }
  }, [lists]);

  useEffect(() => {
    if (filters) {
      reset({
        geographies: filters.geographies,
        products: filters.products,
        segments: filters.segments,
        indicatorsProperties: filters.indicatorsProperties,
      });
    }
  }, [filters]);

  return (
    <div className={styles.modal}>
      <button
        type="button"
        className={styles.close}
        onClick={() => close()}
      >
        <IoClose />
      </button>
      <h1>export powerpoint</h1>
      <div className={styles.form}>
        <div className={styles.field}>
          <StyledSelect
            options={options.geographies}
            name="geographies"
            control={control}
            isMulti
            label="Geographies"
          />
        </div>
        <div className={styles.field}>
          <StyledSelect
            options={options.treatmementSegments}
            name="segments"
            control={control}
            isMulti
            label="Treatmement segments"
          />
        </div>
        <div className={styles.field}>
          <StyledSelect
            options={options.indicatorsProperties}
            name="indicatorsProperties"
            control={control}
            isMulti
            label="MRB Data"
          />
        </div>
        <div className={styles.field}>
          <StyledSelect
            options={options.products}
            name="products"
            control={control}
            isMulti
            label="Products"
          />
        </div>
      </div>
      <div className={styles.submit}>
        <Button
          handleClick={() => onSubmit()}
          isLoading={isLoading}
          className="primary"
        >
          Export
        </Button>
      </div>
    </div>
  );
}
