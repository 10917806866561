import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';

import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useWatch } from 'react-hook-form';
import { FiCalendar } from 'react-icons/fi';
// GrFormView

import styles from './datepicker.module.scss';
import './datepicker.scss';

registerLocale('fr', fr);

const StyledDatePicker = forwardRef(({
  name,
  control,
  required,
  placeholder = '',
  widthLabel = null,
  inline = false,
  label = null,
}, ref) => {
  const inputWatch = useWatch({
    control,
    name,
  });

  const [date, setDate] = useState();

  useEffect(() => {
    setDate(inputWatch);
  }, [inputWatch]);

  useImperativeHandle(ref, () => ({
    getValue: () => date,
    getObject: () => ({ [name]: date }),
  }));

  function isSameDay(date1, date2) {
    if (
      date1
      && date2
      && date1.getDate() === date2.getDate()
      && date1.getMonth() === date2.getMonth()
      && date1.getFullYear() === date2.getFullYear()
    ) {
      return true;
    } return false;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={required ? {
        required: 'Ce champs date est obligatoire',
      } : {}}
      render={({
        field: {
          // name,
          value,
          ref: inputRef,
          onChange,
          onBlur,
        },
      }) => (
        <div className={inline ? `container-custom-date-picker ${styles['date-picker']} ${styles.inline}` : `container-custom-date-picker ${styles['date-picker']}`}>
          { label && (
          <label
            htmlFor={name}
            style={widthLabel ? { minWidth: widthLabel } : {}}
          >
            {label}
          </label>
          )}
          <div className={`${styles['container-input']}`}>
            <DatePicker
              name={name}
              onBlur={onBlur()}
              selected={value}
              onChange={(dateValue) => onChange(dateValue)}
              locale="fr"
              dateFormat="dd/MM/yyyy"
              placeholderText={placeholder}
              popperPlacement="bottom-center"
              customInput={<Input inputRef={inputRef} />}
              dayClassName={(d) => (isSameDay(d, value) ? `${styles.day} ${styles.selected}` : `${styles.day}`)}
            />
            <div className={styles['container-icon']}>
              <FiCalendar />
            </div>
          </div>
        </div>
      )}
    />
  );
});

const Input = forwardRef(({
  onChange,
  placeholder,
  value,
  id,
  onClick,
}, ref) => (
  <input
    ref={ref}
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    id={id}
    onClick={onClick}
  />
));

Input.displayName = 'Input';

StyledDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
};

export default StyledDatePicker;
