import React, { useState, useEffect } from 'react';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import styles from './HtmlEditor.module.scss';
import './toolbar.scss';

function HtmlEditor({ onChange, defaultValue, toolbarEnabled = true }) {
  const [editorState, setEditorState] = useState();

  const toolbar = {
    options: ['inline', 'textAlign', 'blockType', 'list'],
    blockType: {
      inDropdown: false,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline'],
    },
  };

  const toolbarHidden = {
    options: [],
  };

  function generateEditorStateFromValue(val) {
    const contentState = ContentState.createFromBlockArray(convertFromHTML(val));
    return EditorState.createWithContent(contentState);
  }

  useEffect(() => {
    if (defaultValue) {
      setEditorState(generateEditorStateFromValue(defaultValue));
    }
  }, [defaultValue]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    const currentContentAsHTML = convertToHTML({
      entityToHTML: (entity, originalText) => originalText,
    })(state.getCurrentContent());
    onChange(currentContentAsHTML);
  };

  return (
    <div className={styles.container}>
      {editorState && defaultValue
      && (
      <Editor
        defaultEditorState={editorState}
        toolbar={toolbarEnabled ? toolbar : toolbarHidden}
        toolbarClassName={styles.toolbar}
        editorClassName={styles.editor}
        onEditorStateChange={handleEditorChange}
      />
      )}
    </div>
  );
}

export default HtmlEditor;
