import { saveAs } from 'file-saver';
import { postData, getData } from './index';

import {
  ERROR_PRICE,
  GET_PRICES_LISTS,
  GET_PRICES_FILTERED,
  GET_COUNTRIES_FILTERED,
  EXPORT_COUNTRIES_FILTERED,
  PRICE_IS_LOADING,
} from './types';

export async function getPricesListsAction(dispatch) {
  const url = '/indicators/lists';
  let lists;
  await getData(ERROR_PRICE, url, dispatch, true).then((response) => {
    lists = response.lists;
  });
  dispatch({
    type: GET_PRICES_LISTS,
    payload: lists,
  });
}

export async function postPricesFilteredAction(dispatch, obj) {
  const url = '/indicators/price/filtered';
  const data = {
    products: obj.products,
    segments: obj.segments,
    vendors: obj.vendors,
    businessUnits: obj.businessUnits,
    yearQuarter: obj.yearQuarter,
    yearQuarterStart: obj.yearQuarterStart,
  };
  if (obj.geographies.length > 0) {
    data.geographies = obj.geographies;
  }
  let prices;
  dispatch({
    type: PRICE_IS_LOADING,
    payload: true,
  });

  await postData(GET_PRICES_FILTERED, ERROR_PRICE, url, dispatch, data, true).then((response) => {
    prices = response;
  });

  dispatch({
    type: GET_PRICES_FILTERED,
    payload: prices.indicators,
  });
}

export async function postCountriesIndicatorsAction(dispatch, obj) {
  const url = '/indicators/geography/filtered';
  const data = {
    products: obj.products,
    geographies: obj.geographies,
    segments: obj.segments,
    year: obj.yearQuarter.toString().substring(0, 4),
    indicatorsProperties: obj.indicatorsProperties,
    yearQuarter: obj.yearQuarter,
    yearQuarterStart: obj.yearQuarterStart,
  };
  let prices;
  dispatch({
    type: PRICE_IS_LOADING,
    payload: true,
  });

  await postData(GET_COUNTRIES_FILTERED, ERROR_PRICE, url, dispatch, data, true)
    .then((response) => {
      prices = response;
    });

  dispatch({
    type: GET_COUNTRIES_FILTERED,
    payload: prices.indicators,
  });
}

export async function exportIndicatorsAction(dispatch, obj) {
  const url = '/indicators/geography/export';
  let exported = null;
  await postData(EXPORT_COUNTRIES_FILTERED, ERROR_PRICE, url, dispatch, obj, true, true)
    .then((response) => {
      exported = response;
    });
  saveAs(exported, 'export.pptx');
  return exported;
}
