import React from 'react';
import * as d3 from 'd3';
import { BiPlus, BiMinus } from 'react-icons/bi';
import styles from './ProductPrice.module.scss';

export default function ProductPrice({
  product, currency = 'euroValue', color = null, handleChangeProduct = null, productIsActive = null,
}) {
  function getSize() {
    let size = product.barSize || 0;
    if (product.pricesRange) {
      const sizeRange = d3.scaleLinear()
        .domain(product.pricesRange)
        .range([4, 100]);
      size = sizeRange(product?.price?.euroValue);
    }
    return size;
  }

  return (
    <div
      className={`${styles.container} ${!product.price ? styles.empty : ''}`}
    >
      <div className={styles.labels}>
        <div className={styles.name}>
          {handleChangeProduct
            && (
              <button
                type="button"
                className={styles.add}
                onClick={() => handleChangeProduct(product?.product?._id)}
              >
                {productIsActive ? <BiMinus /> : <BiPlus />}
              </button>
            )}
          <p>
            {product?.product?.name}
          </p>
        </div>
        {product.price
          ? (
            <p>
              {product?.price && product?.price[currency].toFixed(2)}
              {' '}
              {product?.price && currency !== 'euroValue' ? product?.price.currency.code : '€'}
            </p>
          ) : (
            <p>n.a</p>
          )}
      </div>
      <div
        className={styles.bar}
        style={{
          width: `${getSize()}%`,
          height: '8px',
          borderRadius: '7px',
          backgroundColor: color || product.priceColor || product.color,
        }}
      />
    </div>
  );
}
