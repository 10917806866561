import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import styles from './Select.module.scss';

function SelectDate({
  options,
  selected,
  handleChangeQuery,
  label,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const searchResult = options
    .filter((opt) => selected !== opt.value)
    .filter((opt) => opt.label.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className={`${styles.container} ${isOpen ? styles.open : ''}`}>
      <div className={styles.title}>
        <p>
          {label }
        </p>
        <button
          type="button"
          className={styles.toggle}
          onClick={() => setIsOpen(!isOpen)}
        >
          <FiChevronDown />
        </button>
      </div>
      <div className={styles.input}>
        <input
          value={search}
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className={styles.options}>
        {searchResult
          .map((opt) => (
            <button
              type="button"
              className={styles.option}
              onClick={() => handleChangeQuery(opt.value)}
              key={opt.value}
            >
              {opt.label}
            </button>
          ))}
        {searchResult.length === 0 && <p>No result</p>}
      </div>

    </div>
  );
}

export default SelectDate;
