import React, {
  useCallback, useEffect, useImperativeHandle, forwardRef, useState, useRef,
} from 'react';
// import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { getPricesListsAction, postCountriesIndicatorsAction, postPricesFilteredAction } from '../../actions/pricesActions';
import Loader from '../Loader';
import Timeline from '../Timeline/Timeline';
import styles from './Filters.module.scss';

import { GET_COUNTRIES_FILTERED, SET_PRICES_FILTERS, SET_QUERY_PRICE } from '../../actions/types';
// import Select from '../Select';
import SelectDate from '../SelectDate';
import GroupedSelect from '../GroupedSelect/GroupedSelect';
import { Button } from '../../lib/HooksFormFields';
import { defaultFilters } from '../../reducers/pricesReducer';
import Select from '../Select';
import ModalStandalone from '../../lib/ModalStandalone';
import ExportModal from '../ExportModal/ExportModal';

// eslint-disable-next-line no-empty-pattern
function Filters({}, ref) {
  const dispatch = useDispatch();
  const {
    lists, filters, isLoading,
  } = useSelector((state) => state.prices);
  const location = useLocation();
  const exportRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [changeIsOpen, setChangeIsOpen] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [vendors, setVendors] = useState([]);

  const getLists = useCallback(() => {
    getPricesListsAction(dispatch);
  }, [dispatch]);

  const arrayKeys = ['geographies', 'segments', 'vendors', 'products', 'businessUnits', 'indicatorsProperties'];
  const periodsOptions = lists?.periods?.filter((p) => {
    if (location?.pathname === '/prices/geography') {
      return p.value < 20231 ? p : null;
    }
    return p;
  });
  const startPeriodsOptions = periodsOptions?.filter((p) => p.value < filters?.yearQuarter);

  const indicatorsOptions = lists?.indicatorsProperties.filter((i) => {
    if (i.treatmentSegment && !filters.segments.find((s) => i.treatmentSegment === s)) {
      return null;
    }
    if (i.geographies && !i.geographies.find((geo) => filters.geographies.find((s) => geo === s))) {
      return null;
    }
    return i;
  });

  const topCompetitor = lists?.topCompetitors?.find(
    (s) => filters?.segments.length === 1 && s.treatmentSegment === filters.segments[0],
  )?.products;

  function getProductColor(id) {
    const product = lists?.products?.find((a) => a.value === id);
    return product?.color;
    // const productIndex = activesProducts?.findIndex((a) => a.value === id);
    // const productsVendor = activesProducts?.filter(
    //   (a) => a.vendor === activesProducts[productIndex]?.vendor,
    // );
    // if (activesProducts && productsVendor?.length > 1) {
    //   const colorRange = d3.scaleLinear()
    //     .domain([0, productsVendor.length + 3])
    //     .range([activesProducts[productIndex]?.color, 'white']);
    //   return colorRange(productIndex);
    // }
    // if (activesProducts?.length > 0 && productIndex !== -1) {
    //   return activesProducts[productIndex]?.color;
    // }
    // return 'white';
  }

  function getSearch() {
    const result = { ...filters };
    if (filters.segments.length === 0) {
      result.segments = lists?.treatmementSegments.map((s) => s.value);
    }
    const entries = searchParams.entries();
    // eslint-disable-next-line
    for(const [key, value] of entries) {
      if (arrayKeys.find((k) => k === key)) {
        const array = value.split(',');
        if (array[0] !== '') {
          result[key] = array;
        } else {
          result[key] = [];
        }
      } else if (key === 'yearQuarter' || key === 'yearQuarterStart') {
        result[key] = value === 'null' ? null : parseFloat(value);
      } else if (key === 'isAbs') {
        result[key] = value !== 'false';
      } else {
        result[key] = value === 'null' ? null : value;
      }
    }
    return result;
  }

  function handleChangeQuery(obj) {
    const searchFilters = getSearch();
    const updatedQuery = { ...searchFilters, ...obj };
    arrayKeys.forEach((k) => {
      updatedQuery[k] = updatedQuery[k].join(',');
    });
    setSearchParams(updatedQuery);
    setIsRefreshed(false);
  }

  function handleSubmit() {
    setIsRefreshed(true);
    if (location?.pathname === '/prices/data') {
      postCountriesIndicatorsAction(dispatch, filters);
    }
    if (location.pathname === '/prices/geography') {
      const updatedFilters = { ...filters };
      postPricesFilteredAction(dispatch, updatedFilters);
    }
  }

  async function resetFilters() {
    setIsRefreshed(false);
    await handleChangeQuery(defaultFilters);
    dispatch({
      type: GET_COUNTRIES_FILTERED,
      payload: [],
    });
  }

  useImperativeHandle(ref, () => ({
    handleChangeQuery: (obj) => handleChangeQuery(obj),
    getProductColor: (code) => getProductColor(code),
  }));

  useEffect(() => {
    getLists();
  }, []);

  useEffect(() => {
    if (!filters.yearQuarter && periodsOptions?.length > 0) {
      const timeValue = periodsOptions[periodsOptions.length - 1].value;
      if (timeValue) {
        handleChangeQuery({ yearQuarter: timeValue });
      }
    }
  }, [filters, periodsOptions]);

  useEffect(() => {
    if (filters.yearQuarterStart && !filters.isAbs) {
      setChangeIsOpen(true);
      // setAbsIsOpen(false);
    } else if (filters.yearQuarterStart && filters.isAbs) {
      // setAbsIsOpen(true);
      setChangeIsOpen(false);
    } else {
      setChangeIsOpen(false);
      // setAbsIsOpen(false);
    }
  }, [filters?.yearQuarterStart, filters.isAbs]);

  useEffect(() => {
    if (location?.search) {
      const searchFilters = getSearch();
      dispatch({
        type: SET_PRICES_FILTERS,
        payload: { ...searchFilters },
      });
      dispatch({
        type: SET_QUERY_PRICE,
        payload: location?.search,
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (location?.pathname === '/prices/geography' && periodsOptions?.length > 0) {
      handleChangeQuery({ geographies: [], price_id: null });
      const timeValue = periodsOptions[periodsOptions.length - 1].value;
      handleChangeQuery({ yearQuarter: timeValue });
    }
  }, [location.pathname]);

  useEffect(() => {
    setIsRefreshed(false);
  }, [filters.segments]);

  useEffect(() => {
    if (lists?.vendors) {
      const vendorsData = [];
      lists.vendors.forEach((v) => {
        const productList = v.lists.filter((p) => {
          if (filters.segments.length === 0) {
            return p;
          }
          if (filters.segments.find((s) => s === p.segment)) {
            return p;
          }
          return null;
        }).filter((p) => {
          if (filters.geographies.length === 0) {
            return p;
          }
          const found = p.geographies.filter((g) => filters.geographies.find((s) => s === g));
          if (found?.length) {
            return p;
          }
          return null;
        });
        const markets = productList.filter((m) => m.type !== 'Registered Prices');
        if (markets.length > 0) {
          vendorsData.push({
            ...v,
            lists: markets,
          });
        }
        const registered = productList.filter((m) => m.type === 'Registered Prices');
        if (registered.length > 0) {
          vendorsData.push({
            ...v,
            value: `${v.value}-Registered`,
            label: `${v.label} (Registered)`,
            lists: registered,
          });
        }
      });
      setVendors(vendorsData);
    }
  }, [filters.segments, lists?.vendors, filters.segments, filters.geographies]);

  return (
    <div className={styles.container} id="filters">
      {lists && filters?.yearQuarter
        ? (
          <>
            <div className={styles.products}>
              <div className={styles.lists}>
                <div className={`${styles.refresh}`}>
                  <Button
                    handleClick={() => handleSubmit()}
                    isLoading={isLoading}
                    isDisabled={(location?.pathname === '/prices/data' && filters.geographies.length === 0) || isRefreshed}
                  >
                    Refresh
                  </Button>
                  {location.pathname === '/prices/data'
                  && (
                  <button
                    type="button"
                    className={styles.export}
                    onClick={() => exportRef.current.open()}
                  >
                    <FiLogOut />
                    {' '}
                  </button>
                  )}
                </div>
                <div className={styles.checkboxes}>
                  {lists?.treatmementSegments?.map((t) => (
                    <button
                      key={t.value}
                      type="button"
                      className={filters.segments.find((s) => s === t.value) ? styles.checked : ''}
                      onClick={() => handleChangeQuery({
                        segments: filters.segments.find((s) => s === t.value)
                          ? filters.segments.filter((s) => s !== t.value)
                          : [...filters.segments, t.value],
                        indicatorsProperties: [],
                        products: [],
                      })}
                    >
                      {filters.segments.find((s) => s === t.value)
                        ? <MdCheckBox color={t.color} />
                        : <MdCheckBoxOutlineBlank />}
                      <p>{t.label}</p>
                    </button>
                  ))}
                </div>
                <div className={`${styles.box} ${styles['product-box']}`}>
                  <div className={`${styles['sub-box']}`}>
                    <GroupedSelect
                      options={lists.geographies}
                      handleChangeQuery={(value) => handleChangeQuery({ geographies: value })}
                      selected={filters.geographies}
                      selectLabel
                      label="Region"
                    />
                  </div>
                  {/* <div className={`${styles['sub-box']}`}>
                    <GroupedSelect
                      options={lists.treatmementSegments.filter((t) => {
                        if (filters.businessUnits.length === 0) return t;
                        if (filters.businessUnits.find((b) => b === t.businessUnit)) return t;
                        return null;
                      })}
                      handleChangeQuery={(value) => handleChangeQuery({
                        segments: value,
                        indicatorsProperties: [],
                        products: [],
                      })}
                      selected={filters.segments}
                      selectLabel
                      label="Treatments segments"
                    />
                  </div> */}
                  {/* <div className={`${styles['sub-box']}`}>
                    <GroupedSelect
                      options={vendors.registered}
                      handleChangeQuery={(value) => handleChangeQuery({ products: value })}
                      selected={filters.products}
                      selectAll
                      count={activesProducts.filter((a) => a.type === 'Registered Prices')?.length}
                      label="Octapharma Registered Prices"
                    />
                  </div> */}
                  <div className={`${styles['sub-box']}`}>
                    <GroupedSelect
                      options={vendors}
                      handleChangeQuery={(value) => handleChangeQuery({ products: value })}
                      topCompetitor={topCompetitor}
                      selected={filters.products}
                      selectAll
                      label="Product Prices"
                    />
                  </div>
                  {location.pathname === '/prices/data' && filters.geographies.length > 0 && filters.segments.length > 0 && lists.indicatorsProperties.length > 0
                  && (
                    <div className={`${styles['sub-box']}`}>
                      <Select
                        options={indicatorsOptions}
                        handleChangeQuery={
                            (value) => handleChangeQuery({ indicatorsProperties: value })
                          }
                        selected={filters.indicatorsProperties.filter(
                          (p) => indicatorsOptions.find((opt) => opt.value === p),
                        )}
                        label="MRB Data"
                      />
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  className={styles.reset}
                  onClick={() => resetFilters()}
                >
                  Reset filters
                </button>
              </div>
            </div>

            <div className={styles.filters}>
              {location.pathname === '/prices/geography'
                && (
                <>
                  <div className={styles.legend}>
                    <div className={styles.item}>
                      <div className={`${styles.icon} ${styles.size}`} />
                      <p>More than 1 products</p>
                    </div>
                    <div className={styles.item}>
                      <div className={`${styles.icon} ${styles.more}`} />
                      <p>positive value</p>
                    </div>
                    <div className={styles.item}>
                      <div className={`${styles.icon} ${styles.less}`} />
                      <p>negative value</p>
                    </div>
                  </div>
                  <div className={styles.dates}>
                    <div className={`${styles.date} ${changeIsOpen ? styles.active : ''}`}>
                      <button
                        type="button"
                        className={styles['date-btn']}
                        onClick={() => {
                          setChangeIsOpen(true);
                          // setAbsIsOpen(false);
                          handleChangeQuery({ isAbs: 'false', yearQuarterStart: startPeriodsOptions[0].value });
                        }}
                      >
                        % Change
                      </button>
                      {changeIsOpen
                  && (
                  <SelectDate
                    options={startPeriodsOptions}
                    handleChangeQuery={(value) => handleChangeQuery({ yearQuarterStart: value })}
                    selected={filters.yearQuarterStart}
                    label={filters?.yearQuarterStart ? `Since ${lists.periods.find((p) => p.value === filters.yearQuarterStart)?.label}` : 'Select a period'}
                  />
                  )}
                    </div>
                    {}
                    <button
                      type="button"
                      onClick={() => handleChangeQuery({ isAbs: 'false', yearQuarterStart: null })}
                      className={`${styles.date} ${!changeIsOpen && !filters.isAbs ? styles.active : ''}`}
                    >
                      Value date select
                    </button>
                  </div>
                </>
                )}
            </div>

            {location.pathname === '/prices/geography'
                && (
                <Timeline
                  options={periodsOptions}
                  handleChangeValue={(value) => handleChangeQuery({ yearQuarter: value })}
                  selectedStart={filters?.yearQuarterStart && parseFloat(filters.yearQuarterStart)}
                  selected={filters?.yearQuarter && parseFloat(filters.yearQuarter)}
                />
                )}
          </>
        )
        : <Loader />}
      <ModalStandalone ref={exportRef}>
        <ExportModal close={exportRef?.current?.close} />
      </ModalStandalone>
    </div>
  );
}

export default forwardRef(Filters);
