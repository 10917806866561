/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { IoClose, IoPricetagOutline } from 'react-icons/io5';

import { FiChevronDown } from 'react-icons/fi';
import styles from './Select.module.scss';

function Select({
  options,
  selected,
  selectedGroup,
  handleChangeQuery,
  count = null,
  closeAfterChange = false,
  hideSelected = false,
  isMulti = true,
  isSearchable = true,
  groupSearch = '',
  isGrouped = false,
  groupId,
  label,
  color,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const group = options.map((opt) => opt.value);

  const isGroupSelected = selectedGroup?.length > 0 && selectedGroup?.length === group.length;

  const searchResult = options
    ?.filter((opt) => {
      if (isGrouped) return !selectedGroup?.find((s) => s === opt.value);
      return !selected?.find((s) => s === opt.value);
    })
    ?.filter((opt) => opt?.label?.toLowerCase().includes(search.toLowerCase()));

  function handleChange(value) {
    if (selected.find((s) => s === value)) {
      return handleChangeQuery(selected?.filter((s) => s !== value));
    }
    if (closeAfterChange) {
      setIsOpen(false);
    }
    if (!isMulti) {
      return handleChangeQuery([value]);
    }
    return handleChangeQuery([...selected, value]);
  }

  function selectGroup() {
    let array = [...new Set([...selected, ...group])];
    if (isGroupSelected) {
      array = selected.filter((s) => !group.find((g) => g === s));
    }
    return handleChangeQuery(array);
  }

  useEffect(() => {
    if (groupSearch.length > 0) {
      setIsOpen(true);
      setSearch(groupSearch);
    } else {
      setIsOpen(false);
      setSearch('');
    }
  }, [groupSearch]);

  return (
    <div className={`${styles.container} ${isGrouped ? styles.grouped : ''} ${isOpen ? styles.open : ''}`}>
      <div className={styles.title}>
        <div className={styles.label}>
          {color && (
            <div
              className={`${styles['group-toggle']} ${isGroupSelected ? styles.active : ''}`}
              style={color && {
                backgroundColor: color,
                border: `2px solid ${color}`,
              }}
            />
          )}
          {groupId
            ? (
              <button
                type="button"
                onClick={() => handleChange(groupId)}
                className={`${styles.text} ${selected.find((s) => s === groupId) ? styles.active : ''}`}
              >
                {label}
              </button>
            )
            : (
              <p className={`${styles.text}`}>
                {label}
              </p>
            )}

          {' '}
          {isMulti && !isGrouped && selected.length > 0 && (label !== '+ Add a market product' && label !== '+ Add a register price') && (
            <span className={styles.count}>
              {selected.length}
            </span>
          )}
          {isMulti && isGrouped && selectedGroup?.length > 0 && (label !== '+ Add a market product' && label !== '+ Add a register price') && (
            <span className={styles.count}>
              {selectedGroup?.length}
            </span>
          )}
          {count > 0
            && (
            <span className={styles.count}>
              {count}
            </span>
            )}
        </div>
        {options.length > 0
        && (
        <button
          type="button"
          className={styles.toggle}
          onClick={() => setIsOpen(!isOpen)}
        >
          <FiChevronDown />
        </button>
        )}
      </div>
      <div className={`${styles.options}`}>
        {isGrouped && (
        <button
          type="button"
          onClick={() => selectGroup()}
          className={styles.option}
        >
          <div className={`${styles['group-toggle']} ${isGroupSelected ? styles.active : ''}`} />
          {isGroupSelected ? 'Remove all' : 'Select all'}
        </button>
        )}
      </div>
      <div className={`${styles.options} ${styles.active}`}>
        {isMulti && (isOpen || isGrouped) && (!hideSelected) && options
          .filter((opt) => selected.find((s) => s === opt.value))
          .map((opt) => (
            <button
              type="button"
              className={`${styles.option}`}
              onClick={() => handleChange(opt.value)}
              style={opt.color && {
                background: opt.color,
              }}
              key={opt.value}
            >
              {opt.type === 'Registered Prices'
                && (
                <div
                  className={styles['is-register']}
                  style={opt.color && {
                    color: opt.color,
                  }}
                >
                  <IoPricetagOutline />
                </div>
                )}
              {opt.label}
              <IoClose />
            </button>
          ))}
      </div>
      {(isSearchable || !isGrouped)
      && (
        <div className={styles.input}>
          <input
            value={search}
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      )}
      <div className={`${styles.options}`}>
        {searchResult
          .map((opt) => (
            <button
              type="button"
              className={styles.option}
              onClick={() => handleChange(opt.value)}
              key={`${opt.value}-${opt?.type}`}
            >
              {opt?.color && (
              <div
                className={styles.icon}
                style={{
                  backgroundColor: opt.color,
                }}
              />
              )}
              {opt.label}
              {opt.type === 'Registered Prices'
                && (
                <div className={styles['is-register']}>
                  <IoPricetagOutline />
                </div>
                )}
            </button>
          ))}
        {search && searchResult.length === 0 && <p>No result</p>}
      </div>

    </div>
  );
}

export default Select;
