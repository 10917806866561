import { postData } from './index';

import {
  ERROR_FORM,
  POST_FORM_INDICATORS,
} from './types';

export async function updateCountryIndicatorsAction(dispatch, data) {
  const url = '/indicators/country/update';

  let res = null;

  await postData(POST_FORM_INDICATORS, ERROR_FORM, url, dispatch, data, true).then((response) => {
    res = response;
  });

  dispatch({
    type: POST_FORM_INDICATORS,
    payload: res.indicators,
  });
}

export async function getCountryIndicatorsAction(dispatch, obj) {
  const url = '/indicators/country';
  let res = null;
  const data = { ...obj };
  delete data.year;

  await postData(POST_FORM_INDICATORS, ERROR_FORM, url, dispatch, data, true).then((response) => {
    res = response;
  });

  dispatch({
    type: POST_FORM_INDICATORS,
    payload: res.indicators,
  });
}

export async function deleteCountryIndicatorsAction(dispatch, obj) {
  const url = '/indicators/country/delete';
  let res = null;
  const data = { ...obj };

  await postData(POST_FORM_INDICATORS, ERROR_FORM, url, dispatch, data, true).then((response) => {
    res = response;
  });

  dispatch({
    type: POST_FORM_INDICATORS,
    payload: res.indicators,
  });
}
