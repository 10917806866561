/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { IoPowerSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Nav.module.scss';
import logo from '../../assets/images/logo.svg';
import { logoutAction } from '../../actions/authActions';

export default function index() {
  const dispatch = useDispatch();
  const prices = useSelector((state) => state.prices);
  const url = 'http://ch1srv1006:8080/BOE/OpenDocument/opendoc/openDocument.jsp?sIDType=CUID&iDocID=M2M26SIACYPqAhwAfgAAVV4qAgpAyUwAAAA';
  return (
    <nav className={styles.container}>
      <NavLink to={`/prices/data${prices.query}`} className={styles.logo}>
        <img src={logo} alt="logo" />
      </NavLink>

      <div className={styles.links}>
        <NavLink to={`/prices/data${prices.query}`} className={({ isActive }) => (isActive ? styles['active-link'] : '')}>
          <p>Data</p>
        </NavLink>
        <NavLink
          to={`/prices/geography${prices.query}`}
          className={({ isActive }) => (isActive ? styles['active-link'] : '')}
        >
          <p>GEOGRAPHY</p>
        </NavLink>
        {/* <NavLink
          to={`/form${prices.query}`}
          className={({ isActive }) => (isActive ? styles['active-link'] : '')}
        >
          <p>Input Data</p>
        </NavLink> */}
      </div>
      <div className={styles.out}>
        <a
          target="_blank"
          href={url}
          rel="noreferrer"
        >
          Price Tracker
        </a>
        <button
          type="button"
          className={styles.logout}
          onClick={() => logoutAction(dispatch)}
        >
          <IoPowerSharp size={22} />
        </button>
      </div>
    </nav>
  );
}
